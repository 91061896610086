import React, { Component } from "react";
import Navbar from "../Pages/Header/Navbar";
import "react-toastify/dist/ReactToastify.css";
import {
  getAdminDetails,
  changePassword,
  updateAdminProfile,
} from "../api/apiService";
import { toast, ToastContainer } from "react-toastify";

export default class Setting extends Component {
  state = {
    userDetail: {},
    fname: "",
    lname: "",
    phn: "",
    uid: "",
    newPass: "",
    confirmPass: "",
    currentPass: "",
    loader: false,
    roleId: "",
  };

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails = async () => {
    const response = await getAdminDetails();
    if (response.status === 1) {
      this.setState({
        userDetail: response.data.data,
        fname: response.data.firstName,
        lname: response.data.lastName,
        uid: response.data.id,
        email: response.data.email,
      });
    } else {
      this.props.history.push("/");
    }
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  changeDetail = async (e) => {
    e.preventDefault();
    let obj = "";
    obj = { firstName: this.state.fname, lastName: this.state.lname };
    const response = await updateAdminProfile(obj);
    if (response.data.status === 1) {
      toast.success(response.data.message);
      this.getUserDetails();
    } else {
      alert(response.data.message);
    }
  };

  passwordValidation = (password) => {
    var upperCaseLetters = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (
      password.match(upperCaseLetters) &&
      password.match(numbers) &&
      password.length >= 8
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.newPass === this.state.confirmPass) {
      let obj = {
        currentPass: this.state.currentPass,
        newPass: this.state.newPass,
      };
      if (this.passwordValidation(this.state.newPass)) {
        const response = await changePassword(obj);
        if (response.status === 1) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        this.setState({
          loader: true,
        });
      } else {
        toast.error("You Must Complete Password Conditions!");
      }
    } else {
      toast.error("Confirm Password Not Matched!");
    }
  };

  render() {
    return (
      <div className='section'>
        <Navbar />
        <div class='main-content'>
          <div className='header'>
            <div className='container-fluid'>
              <div className='header-body'>
                <div className='row align-items-end'>
                  <div className='col'>
                    <h6 className='header-pretitle'>Overview</h6>
                    <h1 className='header-title'>Settings</h1>
                  </div>
                  <div className='col-auto display-none'></div>
                </div>
              </div>
            </div>
          </div>
          <div class='container'>
            <div className='row justify-content-between align-items-center'>
              <div class='col-12 col-sm-6 col-lg-6 col-md-6 px-2'>
                <div className='form-group'>
                  <label> First Name </label>
                  <input
                    type='text'
                    className='form-control'
                    name='fname'
                    value={this.state.fname}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div class='col-12 col-sm-6 col-lg-6 col-md-6 px-2'>
                <div className='form-group'>
                  <label> Last Name </label>
                  <input
                    type='text'
                    className='form-control'
                    name='lname'
                    onChange={this.handleChange}
                    value={this.state.lname}
                  />
                </div>
              </div>
              <div class='col-12 col-sm-12 col-lg-12 col-md-12'>
                <div className='form-group'>
                  <label>Email</label>

                  <input
                    type='email'
                    className='form-control text-secondary'
                    disabled
                    value={this.state.email}
                  />
                </div>
              </div>
              <div class='col-12 col-sm-12 col-lg-12 col-md-12'>
                <div className='form-group'>
                  <label>Password</label>
                  <input
                    type='Password'
                    className='form-control text-secondary'
                    disabled
                    value={"********"}
                  />
                </div>
              </div>
              <div class='col-12'>
                <div className='form-group'>
                  <button class='btn btn-primary' onClick={this.changeDetail}>
                    {" "}
                    Save{" "}
                  </button>
                </div>
                <hr class='my-5'></hr>
              </div>
              <div class='col-12 col-md-9 col-xl-7 mb-5'>
                <h2 class='mb-2'> Change your password </h2>
                <p class='textPink mb-xl-0'>
                  Changing password will immediately change you password. You
                  will need to login with the new password in your next sign-in
                  to the dashboard.
                </p>
              </div>
              <div className='col-12 col-md-6 order-md-2 mb-4'>
                <div className='card bg-light border ml-md-4'>
                  <div className='card-body'>
                    <p className='mb-2'>Password requirements</p>
                    <p className='small textPink mb-2'>
                      To create a new password, you have to meet all of the
                      following requirements:
                    </p>
                    <ul className='small textPink pl-4 mb-0'>
                      <li>Minimum 8 character</li>
                      <li>At least one special character</li>
                      <li>At least one number</li>
                      <li>Can’t be the same as a previous password</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6  mb-4'>
                <form>
                  <div className='form-group'>
                    <label>Current Password</label>
                    <input
                      type='password'
                      className='form-control'
                      name='currentPass'
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className='form-group'>
                    <label>New password</label>
                    <input
                      type='password'
                      className='form-control'
                      name='newPass'
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Confirm new password</label>
                    <input
                      type='password'
                      className='form-control'
                      name='confirmPass'
                      onChange={this.handleChange}
                    />
                  </div>
                  <button
                    className='btn  text-center btn-primary'
                    onClick={this.handleSubmit}
                  >
                    Update password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position='top-center' autoClose={3000} />
      </div>
    );
  }
}
