import {
  // react,
  useEffect,
  useState,
} from "react";
import Navbar from "./Header/Navbar";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { FaRupeeSign } from "react-icons/fa";
import { FaPercentage } from "react-icons/fa";
import { getAppConfiguration } from "../api/apiService";

const AppConfiguration = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await getAppConfiguration();
      console.log(response.data, "asdlk");
      setData(response.data);
    }
    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <div className='main-content'>
        <div className='header'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-end'>
                <div className='col'>
                  <h1 className='header-title'> AppConfiguration</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div class='card w-100 mx-auto my-5 shadow-lg text-center'>
                <div class='card-body my-3'>
                  <h3 class='card-title my-4'>Sign Up Bonus</h3>
                  <div className='d-flex justify-content-center my-3'>
                    <div class='col-sm-4'>
                      <input
                        type='email'
                        class='form-control'
                        value={data.SignupBonusAmount}
                        disabled
                      />
                    </div>
                    <p className='align-self-center mt-3 mx-1'>
                      &nbsp;&nbsp;
                      <FaRupeeSign />
                      &nbsp;&nbsp;
                    </p>
                    <button type='button' class='btn btn-success mb-2 '>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div class='card w-100 mx-auto my-5 shadow-lg text-center'>
                <div class='card-body my-3'>
                  <h3 class='card-title my-4'>Management Bonus</h3>
                  <div className='d-flex justify-content-center my-3'>
                    <div class='col-sm-4'>
                      <input
                        type='email'
                        class='form-control'
                        value={data.BonusAmountPercentage}
                        disabled
                      />
                    </div>
                    <p className='align-self-center mt-3 mx-1'>
                      &nbsp;&nbsp;
                      <FaPercentage />
                      &nbsp;&nbsp;
                    </p>
                    <button type='submit' class='btn btn-success mb-2'>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div class='card w-100 mx-auto my-5 shadow-lg text-center'>
                <div class='card-body my-3'>
                  <h3 class='card-title my-4'>Refer Bonus</h3>
                  <div className='d-flex justify-content-center my-3'>
                    <div class='col-sm-4'>
                      <input
                        type='email'
                        class='form-control'
                        value={data.referBonusAmount}
                        disabled
                      />
                    </div>

                    <p className='align-self-center mt-3 mx-1'>
                      {" "}
                      &nbsp;&nbsp;
                      <PeopleAltIcon />
                      &nbsp;&nbsp;
                    </p>
                    <button type='submit' class='btn btn-success mb-2'>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AppConfiguration;
