import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logIn } from "../api/apiService";
import { AUTH_TOKEN } from "../store/sessionStogrageKey";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class SignIn extends Component {
  state = {
    email: "",
    password: "",
    passwordType: true,
  };

  componentDidMount() {
    if (localStorage.getItem(AUTH_TOKEN)) {
      this.props.history.replace("/");
    }
  }

  showPassword = () => {
    let passwordType = this.state.passwordType;
    if (passwordType) {
      this.setState({ passwordType: false });
    } else {
      this.setState({ passwordType: true });
    }
  };

  isPasswordType = () => {
    let passwordType = this.state.passwordType;
    if (passwordType) return "password";
    return "text";
  };

  handleTextChange = (event) => {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  };

  handleValidation(email, password) {
    if (!email || !password) {
      toast.error("Input fields can't be empty");
      return false;
    }
    return true;
  }

  handleLoginSubmit = async (event) => {
    // this.email.includes("@", "s") ? "d-none" : "visible";

    const errorMail = document.getElementById("error_mail");
    const errorPwd = document.getElementById("error_pwd");

    if (
      !this.state.email.includes("@", ".") ||
      !this.state.email.includes(".") ||
      this.state.email == ""
    ) {
      errorMail.classList.remove("invisible");
      errorMail.classList.add("visible");
    } else {
      errorMail.classList.add("invisible");
    }

    if (!this.state.password) {
      errorPwd.classList.remove("invisible");
      errorPwd.classList.add("visible");
    } else {
      errorPwd.classList.add("invisible");
    }

    event.preventDefault();
    localStorage.removeItem(AUTH_TOKEN);
    const { email, password } = this.state;
    if (!this.handleValidation(email, password)) return;
    const response = await logIn(email, password);

    // console.log(response, "response");
    if (response.status === 1) {
      localStorage.setItem(AUTH_TOKEN, response?.token);
      if (this.props?.location.state) {
        this.props.history.replace({
          pathname: this.props?.location.state.cameFrom,
          search: this.props?.location.search ?? "",
          state: { state: this.props?.location.state?.state },
        });
        return;
      }
      this.props.history.push("/dashboard");
    } else {
      toast.error(response.message, {
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // console.log(this.state.email, "this 000000000000000000000");
  };

  render() {
    return (
      <>
        <div className='d-flex align-items-center vh-100 bg-gradient'>
          <div className='container w-80'>
            <div className='row justify-content-center bg-white p-5 signIncont'>
              <div className='col-12 col-md-6 col-xl-6 col-lg-6 my-5 px-5'>
                <div className='text-center'>
                  <img className='h-25 w-25 my-2' src='./banner.png' />
                </div>
                <h1 className='display-4 text-center mb-3'> Sign in</h1>
                <form onSubmit={this.handleLoginSubmit}>
                  <div className='form-group'>
                    <label>Email Address</label>{" "}
                    <span className='text-danger'>*</span>
                    <input
                      name='email'
                      className='form-control'
                      placeholder='name@address.com'
                      value={this.state.email}
                      onChange={this.handleTextChange}
                      required
                    />
                    {/* {this.state.email.includes("@") ? "yes" : "no"} */}
                    {/* {console.log(typeof this.state.email, "this.state.email")} */}
                    <div id='error_mail' className='invisible text-danger'>
                      please enter a valid mail
                    </div>
                  </div>
                  <div className='form-group'>
                    <label>Password</label>{" "}
                    <span className='text-danger'>*</span>
                    <div className='input-group input-group-merge'>
                      <input
                        type={this.isPasswordType()}
                        name='password'
                        className='form-control form-control-appended'
                        placeholder='Enter your password'
                        value={this.state.password}
                        onChange={this.handleTextChange}
                        required=''
                      />

                      <div className='input-group-append'>
                        <span className='input-group-text'>
                          <i
                            className='fe fe-eye'
                            onClick={this.showPassword}
                          />
                        </span>
                      </div>
                    </div>
                    <div className='invisible text-danger' id='error_pwd'>
                      please fill password field
                    </div>
                  </div>
                  <button className='btn btn-lg btn-block btn-primary mb-3 ccc'>
                    {" "}
                    Sign in{" "}
                  </button>
                </form>
              </div>

              <div
                className='col-12 col-md-6 col-xl-6 col-lg-6 my-5 '
                style={{ display: "inherit" }}
              >
                {" "}
                <img
                  style={{ filter: "brightness(1.2)" }}
                  width='100%'
                  src='./signIn.png'
                  alt='...'
                />
              </div>
            </div>
          </div>
          <ToastContainer position='top-center' autoClose={2000} />
        </div>
      </>
    );
  }
}

export default withRouter(SignIn);
