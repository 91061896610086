import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Pages/Header/Navbar";
import { getUserListData, updateStatusApi } from "../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";

const types = [
  { value: "", label: "All" },
  { value: "0", label: "Not Uploaded" },
  { value: "1", label: "Verification pending" },
  { value: "2", label: "Approved" },
  { value: "3", label: "Rejected" },
];

const SessionStorage_State_Key = "CreateUser";

export default class User extends Component {
  state = {
    sortIs: false,
    currentPage: 1,
    LoadingIs: false,
    userListData: [],
    totalPages: 0,
    userListSearch: "",
    selectStatus: { value: "", label: "KYC Status Filter" },
    type: "",
    indexCount: 0,
    id: "",
	status  :"", 
  text:"",
  };

  compareBy = (key) => {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  };

  sortBy = (key) => {
    let arrayCopy = [...this.state.userListData];
    // console.log(arrayCopy, "EEEEEEEEEee");
    // arrayCopy.sort(this.compareBy(key));
    arrayCopy.sort((a, b) => a[key] - b[key]);
    this.setState({ userListData: arrayCopy });
  };

  handleUserList = async (page, search, type) => {
    this.setState({
      LoadingIs: true,
    });
    let response = await getUserListData(page, search, type);
    if (response.status === 1) {
      let res = response.data;
      let arr = [];
      res.forEach((value) => {
        arr.push({
          firstName: value.firstName,
          email: value.email,
          customer: value.customerId,
          status: value.status,
          documentStatus: value.documentStatus,
          id: value.id,
          expiryDate: value.expiryDate,
          subscription: value.subscription,
          lastName: value.lastName,
          phoneNumber: value.phoneNumber,
          createdAt: moment(value.createdAt).format("MM/DD/YYYY  h:mm a"),
        });
      });
      this.setState({
        userListData: arr,
        totalPages: response.totalPages,
        LoadingIs: false,
      });
    } else {
      toast.error(response.message);
    }
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    this.setState({ indexCount: data.selected }, () => {
      this.handleUserList(
        selectedData,
        this.state.userListSearch,
        this.state.type
      );
    });
  };

  handleChange = (selectedOption) => {
    this.setState(
      { selectStatus: selectedOption, type: selectedOption.value },
      () => {
        this.handleUserList(
          this.state.currentPage,
          this.state.userListSearch,
          this.state.type
        );
      }
    );
  };

  handleUserSearch = (e) => {
    e.preventDefault();
    this.setState({ userListSearch: e.target.value }, () => {
      if (e.target.value.length > 2) {
        this.handleUserList(
          this.state.currentPage,
          this.state.userListSearch,
          this.state.type
        );
      } else if (e.target.value.length === 0) {
        this.handleUserList(
          this.state.currentPage,
          this.state.userListSearch,
          this.state.type
        );
      }
    });
  };

  componentDidMount() {
    const { currentPage, userListSearch, type } = this.state;
    this.handleUserList(currentPage, userListSearch, type);
  }

  getKYCStatus = (status) => {
    if (status === 0) {
      return <span className="badge badge-soft-primary">Not Uploaded</span>;
    } else if (status === 1) {
      return (
        <span className="badge badge-soft-warning">Verification Pending</span>
      );
    } else if (status === 2) {
      return <span className="badge badge-soft-success">Approved</span>;
    } else if (status === 3) {
      return <span className="badge badge-soft-danger">Rejected</span>;
    } else if (status === 4) {
      return <span className="badge badge-soft-warning">Pan Only</span>;
    }
  };

  getUserStatus = (status) => {
    if (status == 2) {
      return <span className="badge badge-soft-danger ">Suspend </span>;
    } else {
      return <span className="badge badge-soft-success">Active</span>;
    }
  };

  updateSessionStateOnNavigation = (navigated = false) => {
    let state = this.state;
    state = { ...state, navigated };
    sessionStorage.setItem(SessionStorage_State_Key, JSON.stringify(state));
  };

  handleactive = (status, id) => {
    if(status == 2){
      this.setState({text:"Re-active"})
    }else{
      this.setState({text:"Suspend"})
    }
    // const realid= confirm("DO You Want .....")
    this.setState({ id : id, status : status });
	 
};


  handlemodal = async (id, status) => {
    let switchStatus;
    // console.log(status, "users/usersStatususers/usersStatususers/usersStatus", id);

    if (status == 1) {
      switchStatus = 2;
    } else {
      switchStatus = 1;
    }
	 

    try {
		const { currentPage, userListSearch, type } = this.state;
      const res = await updateStatusApi({ status: switchStatus, id : id });
      // console.log(res, "eeeeeeeeeeeeeeeee");
	  this.handleUserList(currentPage, userListSearch, type);


    } catch (error) {}
  };

  handlemodalx = () => {
    // console.log("222222222");
  };

  render() {
    const curTime = new Date();
    const { userListData, LoadingIs, userListSearch, indexCount } = this.state;
    return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="header">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row align-items-end">
                  <div className="col">
                    <h6 className="header-pretitle"> Users </h6>
                    <h1 className="header-title"> Listing</h1>
                  </div>
                  {this.props.userRole === 2 ? (
                    <div className="col ml-auto text-right">
                      <Link
                        to="/AddUser"
                        onClick={() => {
                          this.updateSessionStateOnNavigation(true);
                        }}
                        className="btn btn-sm btn-primary"
                      >
                        <i className="fe fe-plus mr-2"></i> Create User
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="col">
                      <h4 className="card-header-title"> List </h4>
                    </div>
                    <div className="col-sm-6">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                          marginLeft: "1rem",
                        }}
                      >
                        {/* <div style={{ width: "100%", marginRight: "1.5rem" }}>
                          <Select
                            onChange={this.handleChange}
                            options={types}
                            value={this.state.selectStatus}
                          />
                        </div> */}
                        <div className="input-group input-group-flush input-group-merge">
                          <input
                            type="search"
                            className="form-control form-control-prepended search"
                            onChange={this.handleUserSearch}
                            value={userListSearch}
                            placeholder="Search"
                          />
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <span className="fe fe-search" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive mb-0 table_height scroll_bar mb-0 x_scroll_width contest_table">
                    <table className="table table-hover table-sm card-table table-center">
                      <thead style={{ position: "static" }}>
                        <tr>
                          <th>#</th>
                          {/* <th>
                            <a
                              href='#/'
                              className='textPink list-sort sort'
                              data-sort='tables-customer'
                              onClick={() => this.sortBy("customer")}
                            >
                              User id
                            </a>
                          </th> */}
                          <th>
                            <a
                              href="#/"
                              className="textPink list-sort sort"
                              data-sort="tables-name"
                              onClick={() => this.sortBy("firstName")}
                            >
                              User name
                            </a>
                          </th>

                          <th>
                            <a
                              href="#/"
                              className="textPink list-sort sort"
                              data-sort="tables-phone"
                              onClick={() => this.sortBy("phoneNumber")}
                            >
                              phone
                            </a>
                          </th>
                          <th>
                            <a
                              href="#/"
                              className="textPink list-sort sort"
                              data-sort="tables-date"
                              onClick={() => this.sortBy("createdAt")}
                            >
                              Date and time
                            </a>
                          </th>
                          {/* <th>
                            <a
                              href="#/"
                              className="textPink list-sort sort"
                              data-sort="tables-date"
                              onClick={() =>
                                this.sortBy("total_no_joinedContest")
                              }
                            >
                              Total Number of participate
                            </a>
                          </th> */}
                          {/* <th>
                            <a
                              href='#/'
                              className='textPink list-sort'
                              data-sort='tables-date'
                              onClick={() => this.sortBy("documentVerified")}
                            >
                              kyc Status
                            </a>
                          </th> */}
                          <th>
                            <a
                              href="#/"
                              className="textPink list-sort"
                              data-sort="tables-date"
                              onClick={() => this.sortBy("isActive")}
                            >
                              User Status
                            </a>
                          </th>

                          <th>
                            <a
                              href="#/"
                              className="textPink list-sort"
                              data-sort="tables-date"
                              onClick={() => this.sortBy("isActive")}
                            >
                              Subscription
                            </a>
                          </th>

                          <th>
                            <a
                              href="#/"
                              className="textPink list-sort"
                              data-sort="tables-date"
                              onClick={() => this.sortBy("isActive")}
                            >
                              Expiry
                            </a>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      {LoadingIs ? (
                        <tbody>
                          <tr>
                            <td colSpan="9">
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {/* {console.log(
                            userListData.length,
                            "2222222222222222222222222222"
                          )} */}
                          {userListData.length > 0 ? (
                            userListData.map((obj, index) => (
                              <tr key={index}>
                                <Link
                                  to={`/userdetail/${obj.id}`}
                                  className="display-content"
                                >
                                  <td>{index + 1}</td>
                                  {/* <td className='tables-customer'>
                                    {obj.customer}
                                  </td> */}
                                  <td className="tables-name">
                                    {`${obj.firstName} ${obj.lastName}`.length >
                                    15
                                      ? `${obj.firstName} `
                                      : `${obj.firstName} ${obj.lastName}`}
                                  </td>
                                  <td className="tables-phone">
                                    {obj?.phoneNumber || "-"}
                                  </td>
                                  <td className="tables-phone">
                                    {obj?.createdAt ? obj.createdAt : ""}
                                  </td>
                                  {/* <td className='text-center'>
                                      {obj?.total_no_joinedContest
                                        ? obj?.total_no_joinedContest
                                        : 0}
                                    </td> */}
                                  {/* <td>
                                    {this.getKYCStatus(obj?.documentStatus)}
                                  </td> */}
                                  <td>
                                    {obj.status == 2 ? (
                                      <span className="badge badge-soft-danger ">
                                        Suspend 
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-success">
                                        Active
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {obj.subscription ? (
                                      <span class="badge badge-soft-success">
                                        Yes
                                      </span>
                                    ) : (
                                      <span class="badge badge-soft-success">
                                        No
                                      </span>
                                    )}
                                  </td>
                                  <td>{obj?.expiryDate}</td>
                                </Link>
                                <td className="text-right">
                                  <div className="dropdown">
                                    <a
                                      href="#/"
                                      className="dropdown-ellipses dropdown-toggle"
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i
                                        className="fe fe-more-vertical"
                                        style={{ color: "#12263F" }}
                                      />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <Link
                                        to={`/userdetail/${obj.id}`}
                                        className="dropdown-item"
                                      >
                                        View Details
                                      </Link>

                                      <Link
                                        onClick={() =>
                                          this.handleactive(obj.status, obj.id) }
                                        className="dropdown-item "
                                        data-toggle="modal"
                                        data-target="#exampleModalLive"
                                      >
										{/* {console.log(obj, "p[[[[[[[[[[[[[[["  )} */}
                                        {obj?.status == 2
                                          ? "Re-Active" 
                                          : "Suspend"}
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>No data Found...</td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-12 align-items-center">
                {this.state.totalPages > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </div>
          </div>
          <ToastContainer position="top-center" autoClose={2000} />
        </div>

        <div
          className="modal"
          id="exampleModalLive"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle"></h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h1>Do You Want To {this.state.text} This User ? </h1>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  autoFocus="off"
                  onClick={() => this.handlemodal(this.state.id, this.state.status )}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
