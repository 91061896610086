import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Navbar from "./Header/Navbar";
import { getPromotionList, deletePromotion } from "../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import { getReadableDateTime } from "../utils/utils";

const SessionStorage_State_Key = "Promotions";

class Promotions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allPromotions: [],
      currentPage: 1,
      pageOffset: 3,
      contestItemLimit: 10,
      searchQuery: "",
      rendered: false,
      navigated: false,
      totalPages: 0,
      LoadingIs: false,
      indexCount: 0,
      dynCount: 50,
    };
  }

  componentDidMount() {
    this.initialized();
  }

  initialized() {
    let sessionState = sessionStorage.getItem(SessionStorage_State_Key);
    let contestItemLimit = 50;
    if (sessionState) {
      sessionState = JSON.parse(sessionState);
      // contestItemLimit = sessionState.contestItemLimit;
      if (sessionState.navigated && sessionState.currentPage != 1) {
        let currentState = this.state;
        Object.assign(currentState, sessionState);
        this.setState({ ...currentState, rendered: true, navigated: false });
        return;
      }
    }
    this.setState({ rendered: true, navigated: false });
    this.getPromotions(undefined, contestItemLimit);
  }

  sessionExpired() {
    this.setState({ LoadingIs: false });
    toast.warn("Your session expired, You have been logged out!", {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    localStorage.clear();
    /**
     * @var cameFrom to redirect here again after login.
     */
    this.props.history.replace({
      pathname: "/",
      state: { cameFrom: "/promotions", state: undefined },
    });
  }

  getPromotions = async (
    currentPage = this.state.currentPage,
    limit = this.state.contestItemLimit,
    searchQuery = this.state.searchQuery
  ) => {
    this.setState({ LoadingIs: true });
    let response = await getPromotionList(currentPage, limit, searchQuery);
    if (response.status == 2) {
      this.sessionExpired();
      return;
    } else if (response.status == 1) {
      // console.log('response.data', response.data)
      this.setState({
        allPromotions: response.data,
        totalPages: response?.totalPages,
        currentPage,
        contestItemLimit: limit,
        LoadingIs: false,
      });
    } else {
      toast.error(response.message);
      return;
    }
  };

  deletePromotions = async (promotionIndex) => {
    let promotions = this.state.allPromotions;

    let response = await deletePromotion(promotions[promotionIndex].id);
    if (response.status == 2) {
      this.sessionExpired();
      return;
    } else if (response.status != 1) {
      toast.error(response.msg);
      return;
    }
    toast.success(response.msg, {
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    promotions.splice(promotionIndex, 1);
    this.setState({ allPromotions: promotions });
  };

  compareBy = (key) => {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  };

  sortBy = (key) => {
    let arrayCopy = [...this.state.allPromotions];
    arrayCopy.sort(this.compareBy(key));
    this.setState({ allPromotions: arrayCopy });
  };

  searchInput = ({ target }) => {
    let searchQuery = target.value;
    this.setState({ searchQuery });
    if (searchQuery.length == 0) {
      this.getPromotions(1, undefined, "");
      return;
    } else if (searchQuery.length < 4) return;

    this.getPromotions(1, undefined, searchQuery);
  };

  // highLightCurrentPage(pageOffset) {
  //     const { currentPage } = this.state;
  //     if (currentPage === pageOffset)
  //         return "page-item active";
  //     else
  //         return "page-item"

  // }

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    this.setState(
      { currentPage: selectedData, indexCount: data.selected },
      () => {
        this.getPromotions(selectedData);
      }
    );
  };

  // onClickPrev(pageOffset) {
  //     const { currentPage } = this.state;

  //     if ((currentPage - 3) < 1) {
  //         this.setState({ pageOffset: (pageOffset - 3) }, () => { this.getPromotions(1) })
  //     }
  //     else
  //         this.setState({ pageOffset: (pageOffset - 3) }, () => { this.getPromotions(currentPage - 3) })
  // }

  // onclickNext(pageOffset) {
  //     this.setState({ pageOffset: (pageOffset + 3) }, () => { this.getPromotions(pageOffset + 1) })
  // }

  // createPaginationButtons() {
  //     const { pageOffset, totalPages } = this.state;
  //     if (totalPages == 1)
  //         return

  //     const buttons = []
  //     if (pageOffset >= 5) {
  //         buttons.push(<li className="page-item"><button className="page-link" onClick={() => { this.onClickPrev(pageOffset) }}>Previous</button></li>)
  //         buttons.push(<li className={this.highLightCurrentPage(pageOffset - 4)}><button className="page-link" onClick={() => { this.getPromotions(pageOffset - 4) }}>{pageOffset - 4}</button></li>)

  //         if (totalPages >= (pageOffset - 3))
  //             buttons.push(<li className={this.highLightCurrentPage(pageOffset - 3)}><button className="page-link" onClick={() => { this.getPromotions(pageOffset - 3) }}>{pageOffset - 3}</button></li>)
  //         if (totalPages >= (pageOffset - 2))
  //             buttons.push(<li className={this.highLightCurrentPage(pageOffset - 2)}><button className="page-link" onClick={() => { this.getPromotions(pageOffset - 2) }}>{pageOffset - 2}</button></li>)
  //         if (totalPages >= (pageOffset - 1))
  //             buttons.push(<li className={this.highLightCurrentPage(pageOffset - 1)}><button className="page-link" onClick={() => { this.getPromotions(pageOffset - 1) }}>{pageOffset - 1}</button></li>)
  //         if (totalPages >= (pageOffset))
  //             buttons.push(<li className={this.highLightCurrentPage(pageOffset)}><button className="page-link" onClick={() => { this.getPromotions(pageOffset) }}>{pageOffset}</button></li>)
  //         if (totalPages > pageOffset)
  //             buttons.push(<li className="page-item"><button className="page-link" onClick={() => { this.onclickNext(pageOffset) }}>Next</button></li>)
  //     }
  //     else {
  //         buttons.push(<li className={this.highLightCurrentPage(pageOffset - 2)}><button className="page-link" onClick={() => { this.getPromotions(pageOffset - 2) }}>{pageOffset - 2}</button></li>)
  //         if (totalPages > 1)
  //             buttons.push(<li className={this.highLightCurrentPage(pageOffset - 1)}><button className="page-link" onClick={() => { this.getPromotions(pageOffset - 1) }}>{pageOffset - 1}</button></li>)
  //         if (totalPages > 2)
  //             buttons.push(<li className={this.highLightCurrentPage(pageOffset)}><button className="page-link" onClick={() => { this.getPromotions(pageOffset) }}>{pageOffset}</button></li>)
  //         if (totalPages > 3)
  //             buttons.push(<li className="page-item"><button className="page-link" onClick={() => { this.onclickNext(pageOffset) }}>Next</button></li>)
  //     }
  //     return buttons;
  // }

  updateSessionStateOnNavigation = (navigated = false) => {
    let state = this.state;
    state = { ...state, navigated };
    sessionStorage.setItem(SessionStorage_State_Key, JSON.stringify(state));
  };

  updateSessionState = () => {
    if (this.state.rendered)
      sessionStorage.setItem(
        SessionStorage_State_Key,
        JSON.stringify(this.state)
      );
  };

  render() {
    this.updateSessionState();
    const { allPromotions, LoadingIs, indexCount, dynCount } = this.state;

    return (
      <>
        <Navbar />
        <div className='main-content'>
          <div className='header'>
            <div className='container-fluid'>
              <div className='header-body'>
                <div className='row align-items-end'>
                  <div className='col'>
                    <h6 className='header-pretitle'> Create </h6>
                    <h1 className='header-title'> Promotions</h1>
                  </div>
                  {/* {this.props.userRole === 2 ? */}
                  <div className='col ml-auto text-right'>
                    <Link
                      to='/add-promotions'
                      className='btn btn-sm btn-primary'
                      onClick={() => {
                        this.updateSessionStateOnNavigation(true);
                      }}
                    >
                      <i className='fe fe-plus mr-2'></i> Create Promotions
                    </Link>
                  </div>
                  {/* : '' */}
                  {/* } */}
                </div>
              </div>
            </div>
          </div>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-header'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h4 className='card-header-title'>List</h4>
                      </div>
                      <form className='d-flex'>
                        <div className='input-group input-group-flush input-group-merge'>
                          <input
                            type='search'
                            className='form-control form-control-prepended search'
                            placeholder='Search'
                            value={this.state.searchQuery}
                            onChange={this.searchInput}
                          />
                          <div className='input-group-prepend'>
                            <div className='input-group-text'>
                              <span className='fe fe-search' />
                            </div>
                          </div>
                        </div>
                        <div className='dropdown'>
                          <select
                            className='form-control slect_form'
                            value={this.state.contestItemLimit}
                            onChange={(event) => {
                              this.setState({ dynCount: event.target.value });
                              this.getPromotions(
                                this.state.currentPage,
                                event.target.value
                              );
                            }}
                          >
                            <option value='50'>Show 50</option>
                            <option value='70'>Show 70</option>
                            <option value='100'>Show 100</option>
                          </select>
                        </div>
                      </form>
                      <div className='col-auto'></div>
                    </div>
                  </div>
                  <div className='table-responsive mb-0 table_height scroll_bar pr_table'>
                    <table className='table table-hover table-sm card-table table-center'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            <a
                              href='#'
                              class='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortBy("promotionsName")}
                            >
                              Promotions name
                            </a>
                          </th>
                          <th>
                            <a
                              href='#'
                              class='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortBy("no_of_permotions")}
                            >
                              No Of Coupons
                            </a>
                          </th>
                          <th>
                            <a
                              href='#'
                              class='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortBy("permotions_value")}
                            >
                              Coupons Value
                            </a>
                          </th>
                          <th>
                            <a
                              href='#'
                              class='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortBy("startTime")}
                            >
                              Start Date
                            </a>
                          </th>
                          <th>
                            <a
                              href='#'
                              class='textPink list-sort'
                              data-sort='tables-row'
                              onClick={() => this.sortBy("endTime")}
                            >
                              End Date
                            </a>
                          </th>
                          <th></th>
                        </tr>
                      </thead>

                      {/* {LoadingIs ? (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div class="spinner-border" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : ( */}
                      <tbody>
                        {/* {allPromotions.length > 0 ? (
                                                        allPromotions.map((eachPromotion, index) => */}
                        <tr>
                          <Link
                            className='display-content'
                            to={{ pathname: "/view-promotions" }}
                          >
                            <td>1</td>
                            <td>om prakash</td>
                            <td>50</td>
                            <td>12</td>
                            <td>08/03/2022, 21:46:00</td>
                            <td>07/05/2022, 21:36:00</td>
                          </Link>
                          <td>
                            <div className='dropdown'>
                              <a
                                href='#'
                                className='dropdown-ellipses dropdown-toggle'
                                role='button'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                              >
                                <i
                                  className='fe fe-more-vertical'
                                  style={{ color: "#12263F" }}
                                />
                              </a>
                              <div className='dropdown-menu dropdown-menu-right p-3'>
                                <div className='drop_list mb-2 dropdown-item '>
                                  <Link to={{ pathname: "/view-promotions" }}>
                                    <span className='fe fe-eye mr-1'></span>{" "}
                                    View
                                  </Link>
                                </div>
                                {/* {this.props.userRole === 2 ?
                                                                                <button className="drop_list dropdown-item table-sm" onClick={() => { this.deletePromotions(index) }}>
                                                                                    <span className="fe fe-trash mr-1"></span> Delete
                                                                                </button>
                                                                                : ''
                                                                            } */}
                              </div>
                            </div>
                          </td>
                        </tr>
                        {/* )
                                                    ) : (
                                                        <tr>
                                                            <td>No data Found...</td>
                                                        </tr>
                                                    )} */}
                      </tbody>
                      {/* )} */}
                    </table>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                {this.state.totalPages > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
                {/* <nav aria-label="Page navigation example">
                                    <ul className="pagination pagination-lg justify-content-center">
                                        {this.createPaginationButtons()}
                                    </ul>
                                </nav> */}
              </div>
            </div>
          </div>
          <ToastContainer position='top-center' autoClose={2000} />
        </div>
      </>
    );
  }
}

export default withRouter(Promotions);
