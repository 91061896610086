// import react from "react";

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Navbar from "./Header/Navbar";
import { deleteBanner, getBannerList } from "../api/apiService";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";

const SessionStorage_State_Key = "Bannerlist";

class Bannerlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allContests: [],
      currentPage: 1,
      pageOffset: 3,
      contestItemLimit: 10,
      searchQuery: "",
      rendered: false,
      navigated: false,
      totalPages: 0,
      LoadingIs: false,
      bannerList: [],
      indexCount: 0,
      dynCount: 50,
    };
  }

  componentDidMount() {
    this.handleBannerList();
    this.initialized();
  }

  handleBannerList = async () => {
    let response = await getBannerList();
    console.log(response,"?????????")
    var res = [];
    if (response.status === 1) {
      res = response.data;
    }
    this.setState({ bannerList: res.length ? res : [] }, () => {
      this.initialized();
    });
  };

  initialized() {
    let sessionState = sessionStorage.getItem(SessionStorage_State_Key);
    if (sessionState) {
      sessionState = JSON.parse(sessionState);

      if (sessionState.navigated && sessionState.currentPage !== 1) {
        let currentState = this.state;
        Object.assign(currentState, sessionState);
        this.setState({ ...currentState, rendered: true, navigated: false });
        return;
      }
    }
  }

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    this.setState(
      { currentPage: selectedData, indexCount: data.selected },
      () => {
        this.getAllContest(selectedData);
      }
    );
  };

  updateSessionState = () => {
    if (this.state.rendered)
      sessionStorage.setItem(
        SessionStorage_State_Key,
        JSON.stringify(this.state)
      );
  };

  updateSessionStateOnNavigation = (navigated = false) => {
    let state = this.state;
    state = { ...state, navigated };
    sessionStorage.setItem(SessionStorage_State_Key, JSON.stringify(state));
  };
  handleDelete = async (id)=>{
    console.log(id,"PPPPPPPPPPPPPPP");
    try{
      const response = await deleteBanner(id);
      toast.success(response.message);
      this.handleBannerList()
    }catch(error){
      toast.error(error)
    }
  }

  render() {
    this.updateSessionState();
    const { bannerList, LoadingIs, indexCount, dynCount } = this.state;

    return (
      <>
        <Navbar />
        <div className='main-content'>
          <div className='header'>
            <div className='container-fluid'>
              <div className='header-body'>
                <div className='row align-items-end'>
                  <div className='col'>
                    <h1 className='header-title'> Banner</h1>
                  </div>
                  {true ? (
                    <div className='col ml-auto text-right'>
                      <Link
                        to='/banner'
                        className='btn btn-sm btn-primary pinkBtn'
                      >
                        <i className='fe fe-plus mr-2'></i> Create Banner
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-header'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <h4 className='card-header-title'>List</h4>
                      </div>
                      <form className='d-flex'>
                        <div className='input-group input-group-flush input-group-merge'>
                          <input
                            type='search'
                            className='form-control form-control-prepended search'
                            placeholder='Search'
                            value={this.state.searchQuery}
                            onChange={this.searchContest}
                          />
                          <div className='input-group-prepend'>
                            <div className='input-group-text'>
                              <span className='fe fe-search' />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='table-responsive mb-0 table_height scroll_bar mb-0 x_scroll_width contest_table'>
                    <table className='table table-hover table-sm card-table table-center'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            <a
                              href='#/'
                              class='textPink list-sort'
                              data-sort='tables-row'
                            >
                              Banner Name
                            </a>
                          </th>
                          <th className='text-center'>
                            <a
                              href='#/'
                              class='textPink list-sort'
                              data-sort='tables-row'
                            >
                              URL
                            </a>
                          </th>
                          <th className='text-center'>
                            <a
                              href='#/'
                              class='textPink list-sort'
                              data-sort='tables-row'
                            >
                              Image
                            </a>
                          </th>
                          <th className='text-center'>
                            <a
                              href='#/'
                              class='textPink list-sort'
                              data-sort='tables-row'
                            >
                              status
                            </a>
                          </th>
                          <th></th>
                        </tr>
                      </thead>

                      {LoadingIs ? (
                        <tbody>
                          <tr>
                            <td colSpan='12'>
                              <div class='spinner-border' role='status'>
                                <span class='sr-only'>Loading...</span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {bannerList.length > 0 ? (
                            bannerList?.map((eachItem, index) => (
                              <tr>
                                <Link
                                  to={{
                                    pathname: "/bannerpageview",
                                    search: `${eachItem.id}`,
                                  }}
                                  onClick={() => {
                                    this.updateSessionStateOnNavigation(true);
                                  }}
                                  class='display-content'
                                  key={eachItem.id + index}
                                >
                                  <td>{indexCount * dynCount + (index + 1)}</td>

                                  <td>
                                    {eachItem.name
                                      ? eachItem.name
                                      : "-"}
                                  </td>
                                  <td className='text-center'>
                                    {eachItem.url}
                                  </td>
                                  <td className='text-center'>
                                    <div
                                      className='mx-auto'
                                      style={{ height: "30px", width: "50px" }}
                                    >
                                      <img
                                        alt='...'
                                        className='rounded w-100 mh-100 mw-100 '
                                        src={`https://kiiquimedias.s3.amazonaws.com/banner-images/${eachItem?.image}`}
                                      />
                                      {/* {console.log(
                                        eachItem?.bannerImages[0]?.s3key,
                                        "yyyy"
                                      )} */}
                                    </div>
                                  </td>
                                  <td className='text-center'>
                                    {eachItem.isActive ? (
                                      <span
                                        className='text-center badge-soft-success px-3 py-1'
                                        style={{ "border-radius": "5px" }}
                                      >
                                        Active
                                      </span>
                                    ) : (
                                      <span
                                        className='text-center badge-soft-danger  px-3 py-1'
                                        style={{ "border-radius": "5px" }}
                                      >
                                        InActive
                                      </span>
                                    )}
                                  </td>
                                  {console.log(eachItem, "8888")}
                                </Link>
                                <td className='text-right'>
                                  <div className='dropdown'>
                                    <a
                                      href='#/'
                                      className='dropdown-ellipses dropdown-toggle'
                                      role='button'
                                      data-toggle='dropdown'
                                      aria-haspopup='true'
                                      aria-expanded='false'
                                    >
                                      <i
                                        className='fe fe-more-vertical'
                                        style={{ color: "#12263F" }}
                                      />
                                    </a>
                                    <div className='dropdown-menu dropdown-menu-right'>
                                      <Link
                                        to={{
                                          pathname: "/bannerpageview",
                                          search: `${eachItem.id}`,
                                        }}
                                        onClick={() => {
                                          this.updateSessionStateOnNavigation(
                                            true
                                          );
                                        }}
                                        className='dropdown-item'
                                        key={`details-${eachItem.id}`}
                                      >
                                        View Details
                                      </Link>
                                      <Link
                                        to={{
                                          pathname: "/editbanner",
                                          search: `${eachItem.id}`,
                                        }}
                                        onClick={() => {
                                          this.updateSessionStateOnNavigation(
                                            true
                                          );
                                        }}
                                        className='dropdown-item'
                                        key={`details-${eachItem.id}`}
                                      >
                                        Edit
                                      </Link>
                                      <Link
                                        onClick={() => {
                                          // this.updateSessionStateOnNavigation(
                                          //   true
                                          // );
                                          this.handleDelete(eachItem.id)
                                        }}
                                        className='dropdown-item'
                                        key={`details-${eachItem.id}`}
                                      >
                                        Delete
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>No data Found...</td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                {this.state.totalPages > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                )}
              </div>
            </div>
          </div>
          {/* <ToastContainer position='top-center' autoClose={2000} /> */}
        </div>
      </>
    );
  }
}

export default withRouter(Bannerlist);
