import {
  // react,
  // useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { addBanner } from "../api/apiService";
import Navbar from "./Header/Navbar";
import { ToastContainer, toast } from "react-toastify";

const Banner = () => {
  const [url, urlsetphoto] = useState("");
  const [bannerName, bannerNamesetphoto] = useState("");
  const [realbanner, setrealbanner] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const history = useHistory();

  const handleTextInputtittle = (event) => {
    bannerNamesetphoto(event.target.value);
  };

  const urlHandle = (event) => {
    urlsetphoto(event.target.value);
  };

  const uploadbanner = (e) => {
    setrealbanner(URL.createObjectURL(e.target.files[0]));
    setSelectedFile(e.target.files[0]);
  };

  const last = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("name", bannerName);
    formData.append("url", url);
    const response = await addBanner(formData);
    if (response.data.status === 1) {
      toast.success(response.data.message);
      setTimeout(() => {
        history.push("/bannerlist");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <>
      <Navbar />

      <div className='main-content'>
        <div className='header'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-end'>
                <div className='col'>
                  <h1 className='header-title '>Add Banner</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-6'>
              <div className='form-group'>
                <label>Banner Tittle</label>
                <input
                  type='text'
                  className='form-control'
                  name='Banner Title'
                  placeholder='Title'
                  onChange={handleTextInputtittle}
                />
              </div>
              <div className='form-group'>
                <label>Upload photo</label>
                <div class='mb-3'>
                  <label for='formFile' class='form-label'></label>

                  <input
                    class='form-control'
                    type='file'
                    id='formFile'
                    accept='image/*'
                    onChange={uploadbanner}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label>URL</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='www.https://example.com'
                  name='contestName'
                  onChange={(e) => urlHandle(e)}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className='card' style={{ height: "280px", width: "435px" }}>
                <img
                  alt=''
                  className='rounded w-100 mh-100 mw-100'
                  src={realbanner}
                />
              </div>
            </div>
          </div>
          <div className='text-center'>
            <div class='btn-group' role='group'>
              <button
                type='button'
                class='btn btn-danger rounded mx-2 pinkBtn'
                onClick={() => last()}
              >
                Create
              </button>
              <button
                type='button'
                class='btn btn-warning rounded mx-2'
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {/* <ToastContainer position='top-center' autoClose={2000} /> */}
      </div>
    </>
  );
};
export default Banner;
