import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Navbar from "./Header/Navbar";
// import { } from '../api/apiService'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ReactPaginate from "react-paginate";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Select from "react-select";
import Csv from "./Csv.js";

import { CsvToHtmlTable } from "react-csv-to-table";

// import { CSVLink } from "react-csv";
import {
	generateAndSaveReports,
	getModuleReportList,
	generateReportsWithdraw,
	getReportListData,
} from "../api/apiService";
import ShowReportHistoryModal from "../Pages/Popup/showReportHistoryModal";
// import ShowReportWithdraw from "../Pages/Popup/showReportWithdraw";

const sampleData = [
	{
		id: 8,
		firstName: "waseem",
		lastName: "shah",
		phoneNumber: 26490123,
		otp: "",
		dateOfBirth: "1-1-2004",
		sexuality: "female",
		age: 20,
		gender: "female",
		pronouns: "qwerwre",
		subscription: true,
		expiryDate: "1-1-2025",
		publish: "fdgfghf",
		unpublish: "sdfdgfd",
		children: 2,
		pets: 1,
		isActive: true,
		maritalStatus: "[]",
		createdAt: "2022-06-24T06:43:39.152Z",
		updatedAt: "2022-06-24T06:43:39.152Z",
	},
	{
		id: 9,
		firstName: "waseem",
		lastName: "shah",
		phoneNumber: 26490923,
		otp: "$2b$10$o2WNdfgJs6QPJNReiubnPO/QXPVRvw1GxJYrAdTS1y82b3RsSuFB2",
		dateOfBirth: "1-1-2004",
		sexuality: "female",
		age: 20,
		gender: "female",
		pronouns: "qwerwre",
		subscription: true,
		expiryDate: "1-1-2025",
		publish: "fdgfghf",
		unpublish: "sdfdgfd",
		children: 2,
		pets: 1,
		isActive: true,
		maritalStatus: "[]",
		createdAt: "2022-06-24T11:06:47.065Z",
		updatedAt: "2022-06-24T11:07:33.365Z",
	},
	{
		id: 10,
		firstName: "waseem",
		lastName: "shah",
		phoneNumber: 264100923,
		otp: "$2b$10$9PcVs6cGy/ZHr0HOKYkUaOfO1UqWGICsZahd3RQTvn.jIrhno0b9q",
		dateOfBirth: "1-1-2004",
		sexuality: "female",
		age: 20,
		gender: "female",
		pronouns: "qwerwre",
		subscription: true,
		expiryDate: "1-1-2025",
		publish: "fdgfghf",
		unpublish: "sdfdgfd",
		children: 2,
		pets: 1,
		isActive: true,
		maritalStatus: "[]",
		createdAt: "2022-06-24T11:07:44.735Z",
		updatedAt: "2022-06-24T11:08:34.741Z",
	},
	{
		id: 11,
		firstName: "waseem",
		lastName: "shah",
		phoneNumber: 26400923,
		otp: "$2b$10$q.PyfB9KGQYrOLKaYgi5neUa.leaWs0lUlFH/9f.cbt/46v1r5d9S",
		dateOfBirth: "1-1-2004",
		sexuality: "female",
		age: 20,
		gender: "female",
		pronouns: "qwerwre",
		subscription: true,
		expiryDate: "1-1-2025",
		publish: "fdgfghf",
		unpublish: "sdfdgfd",
		children: 2,
		pets: 1,
		isActive: true,
		maritalStatus: "[]",
		createdAt: "2022-06-24T11:08:45.128Z",
		updatedAt: "2022-06-24T11:09:27.346Z",
	},
	{
		id: 12,
		firstName: "waseem",
		lastName: "shah",
		phoneNumber: 2629100923,
		otp: "$2b$10$8XG2qz4kWeFv3o2cYSsPxuFUz6j8AO6AS3LDBd3m8oICn9mElu8GS",
		dateOfBirth: "1-1-2004",
		sexuality: "female",
		age: 20,
		gender: "female",
		pronouns: "qwerwre",
		subscription: true,
		expiryDate: "1-1-2025",
		publish: "fdgfghf",
		unpublish: "sdfdgfd",
		children: 2,
		pets: 1,
		isActive: true,
		maritalStatus: "[]",
		createdAt: "2022-06-24T11:12:26.826Z",
		updatedAt: "2022-06-24T11:13:12.594Z",
	},
	{
		id: 13,
		firstName: "waseem",
		lastName: "shah",
		phoneNumber: 262900923,
		otp: "$2b$10$Qa4MMeyrpnZ5spDhSub/F.8Y/ESNXdmLXsCbPo6BTrYEYRlNK/PYq",
		dateOfBirth: "1-1-2004",
		sexuality: "female",
		age: 20,
		gender: "female",
		pronouns: "qwerwre",
		subscription: true,
		expiryDate: "1-1-2025",
		publish: "fdgfghf",
		unpublish: "sdfdgfd",
		children: 2,
		pets: 1,
		isActive: true,
		maritalStatus: "[]",
		createdAt: "2022-06-24T11:13:21.619Z",
		updatedAt: "2022-06-24T11:16:55.268Z",
	},
	{
		id: 14,
		firstName: "waseem",
		lastName: "shah",
		phoneNumber: 262120923,
		otp: "",
		dateOfBirth: "1-1-2004",
		sexuality: "female",
		age: 20,
		gender: "female",
		pronouns: "qwerwre",
		subscription: true,
		expiryDate: "1-1-2025",
		publish: "fdgfghf",
		unpublish: "sdfdgfd",
		children: 2,
		pets: 1,
		isActive: true,
		maritalStatus: "[]",
		createdAt: "2022-06-24T11:17:19.609Z",
		updatedAt: "2022-06-24T11:17:19.609Z",
	},
	{
		id: 15,
		firstName: "waseem",
		lastName: "shah",
		phoneNumber: 26212320923,
		otp: "$2b$10$vVnbXLU/ZABzYkgY9rCkO.wCi38dUiyWKKD1gjQVrgLjW/V3rs/sq",
		dateOfBirth: "1-1-2004",
		sexuality: "female",
		age: 20,
		gender: "female",
		pronouns: "qwerwre",
		subscription: true,
		expiryDate: "1-1-2025",
		publish: "fdgfghf",
		unpublish: "sdfdgfd",
		children: 2,
		pets: 1,
		isActive: true,
		maritalStatus: "[single;divorced;married]",
		createdAt: "2022-06-24T11:21:52.389Z",
		updatedAt: "2022-06-28T06:10:05.726Z",
	},
];

class Report extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// ReportList:[],
			ViewHistoryGeneratedReportsData: null,
			ViewHistoryWithdrawReportsData: null,
			loadingHistory: false,
			loadingHistoryWithdraw: false,
			modules: [
				{
					id: 1,
					name: "Contest",
					value: "contest",
				},
			],
			modulesWithdraw: [
				{
					id: 1,
					name: "Withdraw",
					value: "Withdraw",
				},
			],
			startDate: "",
			endDate: "",
			today: "",
			LoadingIs: "",
			generateReportValue: "",
			userType: "",
			userdata: [],
			contestdata: [],
		};
		this.modelOpenRef = React.createRef();
		this.modelWithdrawRef = React.createRef();
	}

	handleUserList = async () => {
		this.setState({
			LoadingIs: true,
		});
	};

	componentDidMount() {
		const d = new Date();
		this.setState({
			today: d,
			startDate: new Date(d.getFullYear(), d.getMonth(), 1).toString().split("T")[0],

			endDate: new Date(d.getFullYear(), d.getMonth(), d.getDate()).toString().split("T")[0],
		});
	}
	handleState = (state) => {
		this.setState(state);
	};
	ViewHistoryGeneratedReports = async () => {
		this.setState({ loadingHistory: true });
		const getReportsDate = await getModuleReportList();

		if (getReportsDate.status === 1) {
			this.setState({
				ViewHistoryGeneratedReportsData: getReportsDate.data,
				loadingHistory: false,
			});
		} else {
			toast.error("Something went wrong");
		}
	};
	handleDateValidation = (startDate) => {
		let a = moment(new Date(startDate)).format("DD-MM-YYYY");
		let b = moment(new Date(this.state.endDate)).format("DD-MM-YYYY");
		let start = a.split("-");
		let end = b.split("-");
		let startSum = start[0] + start[1] + start[2];
		let endSum = end[0] + end[1] + end[2];

		if (startSum >= endSum) {
			return true;
		} else {
			return false;
		}
	};

	getKYCStatus = (status) => {
		if (status === 0) {
			return <span className='badge badge-soft-primary'>Not Uploaded</span>;
		} else if (status === 1) {
			return <span className='badge badge-soft-warning'>Verification Pending</span>;
		} else if (status === 2) {
			return <span className='badge badge-soft-success'>Approved</span>;
		} else if (status === 3) {
			return <span className='badge badge-soft-danger'>Rejected</span>;
		} else if (status === 4) {
			return <span className='badge badge-soft-warning'>Pan Only</span>;
		}
	};

	getUserStatus = (status) => {
		if (status) {
			return <span className='badge badge-soft-danger '>Suspend </span>;
		} else {
			return <span className='badge badge-soft-success'>Active</span>;
		}
	};

	generateAndSaveReportsFromServer = async () => {
		const { startDate, endDate, userType } = this.state;

		if (userType) {
			const payload = {
				fromDate: startDate,
				toDate: endDate,
				userType: userType,
			};
			console.log(payload, "llllllllllll");
			let getResponseOfGeneratedReports = await generateAndSaveReports(payload);
			console.log(getResponseOfGeneratedReports, "22222222");
			if (getResponseOfGeneratedReports.status === 1) {
				toast.success("Report generated successfully.");
				this.setState({
					userdata: getResponseOfGeneratedReports.userFind
						? getResponseOfGeneratedReports.userFind
						: getResponseOfGeneratedReports.constestFind,
				});
				// this.modelOpenRef.current.click();
			} else {
				toast.error("Report not generated, please try again.");
			}
		} else {
			toast.error("Input field Empty");
		}
	};

	generateReportsWithdrawFromServer = async () => {
		const { startDate, endDate } = this.state;
		console.log("ddd", this.state);
		const payload = {
			module: "WithdrawRequest ",
			fromDate: new Date(startDate),
			toDate: new Date(endDate),
		};
		let getResponseOfWithdrawReports = await generateReportsWithdraw(payload);
		console.log("getResponseOfWithdrawReports", getResponseOfWithdrawReports);
		if (getResponseOfWithdrawReports) {
			toast.success("Report generated successfully.");
			// console.log('getResponseOfWithdrawReports after',this.modelWithdrawRef.current.click())
			// this.setState({ViewHistoryWithdrawReportsData:getResponseOfWithdrawReports.data})
			// this.modelWithdrawRef.current.click()
		} else {
			toast.error("Report not generated, please try again.");
		}
	};

	handleSelectChange = async (e) => {
		console.log(e, "0000");
		this.setState({ userType: e });
	};

	render() {
		const {
			// ReportList,
			// today,
			LoadingIs,
			// startDate,
			// endDate,
			modules,
			// modulesWithdraw,
		} = this.state;
		// console.log(this.state)

		const type = [
			{ value: "1", label: "user" },
			{ value: "2", label: "user 2" },
		];

		return (
			<>
				<Navbar />

				<div className='main-content'>
					<div className='header'>
						<div className='container-fluid'>
							<div className='header-body'>
								<div className='row align-items-end'>
									<div className='col'>
										<h6 className='header-pretitle'> Create </h6>
										<h1 className='header-title'> Report</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='container-fluid'>
						<div className='row'>
							<div className='col-sm-3 mx-3'>
								<div className='form-group'>
									<label>User Type</label>
									<Select
										// value={this.state.asset_type == "1" ? type[0] : type[1]}
										onChange={({ label }) => {
											this.handleSelectChange(label);
										}}
										options={type}
										placeholder='select any option'
									/>
								</div>
							</div>

							<div className='col-sm-3 mx-3'>
								<div className='form-group'>
									<label>From Date</label>
									<Flatpickr
										value={this.state.startDate}
										placeholder='Select Date ......'
										className='form-control'
										options={{ dateFormat: "d-m-Y", maxDate: this.state.today }}
										onChange={(date) => {
											let valid = this.handleDateValidation(new Date(date).toISOString());
											// console.log("valid", date);
											if (valid) {
												this.setState({
													startDate: new Date(date).toISOString(),
													endDate: new Date(date).toISOString(),
												});
											}
											this.setState({
												startDate: new Date(date).toISOString(),
											});
										}}
									/>
								</div>
							</div>
							<div className='col-sm-3 mx-3'>
								<div className='form-group'>
									<label>To Date</label>
									<Flatpickr
										value={this.state.endDate}
										className='form-control'
										placeholder='Select Date ......'
										options={{
											minDate: this.state.startDate,
											maxDate: this.state.today,
											dateFormat: "d-m-Y",
										}}
										onChange={(date) => {
											this.setState({ endDate: new Date(date).toISOString() });
										}}
									/>
								</div>
							</div>
							<div className='col-md-2 text-start mx-2'>
								<div
									className='form-group'
									style={{
										paddingTop: "1.8rem",
										display: "flex",
										flexDirection: "row",
										// justifyContent: "flex-end",
									}}>
									<button className='btn btn-primary pinkBtn' onClick={() => this.generateAndSaveReportsFromServer()}>
										Submit
									</button>
								</div>
							</div>
							<div className='col-12 mt-4'>
								<div className='card'>
									{/* <div className="card-header">
                    <div className="row align-items-center">
                    <div className="col">
                    <h4 className="card-header-title">List</h4>
                    </div>
                    <div className="col-auto" />
                    </div>
                    </div> */}
									<div className='table-responsive mb-0 table_height scroll_bar pr_table'>
										<table className='table table-hover table-sm card-table table-center'>
											<thead>
												<tr>
													<th>
														{" "}
														<span>Report Name</span>
														<span style={{ float: "right" }}>Action</span>
													</th>
												</tr>
											</thead>
											{LoadingIs ? (
												<tbody>
													<tr>
														<td colSpan='2'>
															<div class='spinner-border' role='status'>
																<span class='sr-only'>Loading...</span>
															</div>
														</td>
													</tr>
												</tbody>
											) : (
												<tbody>
													{modules.length > 0 ? (
														modules.map((obj, index) => (
															<tr key={obj.id}>
																<td style={{ textAlign: "end" }}>
																	<a
																		href='#ShowReportHistoryModal'
																		className='btn btn-primary btn-sm mr-3 pinkBtn'
																		data-toggle='modal'
																		data-target='#ShowReportHistoryModal'
																		onClick={this.ViewHistoryGeneratedReports}
																		ref={this.modelOpenRef}>
																		History
																	</a>
																	{/* <button className="btn btn-primary btn-sm mr-3" ></button> */}
																	{this.props.userRole === 2 ? (
																		<button
																			className='btn btn-sm btn-outline-primary'
																			data-backdrop='static'
																			data-toggle='modal'
																			data-target='#generateReport'
																			onClick={() =>
																				this.setState({
																					generateReportValue: obj.value,
																				})
																			}>
																			Generate report
																		</button>
																	) : (
																		""
																	)}
																</td>
															</tr>
														))
													) : (
														<tr>
															<td>No data Found...</td>
														</tr>
													)}
													{console.log(this.state.userdata, "this.state.userdata")}

													{this.state.userType == "contest" ? (
														<table className='mx-auto col-md-12'>
															<thead>
																<tr>
																	<th>#</th>
																	<th>Contest name</th>
																	<th>Contest Type</th>
																	<th className='text-center'>Entry fees</th>
																	<th> Min Participation</th>
																	<th className='text-center'>Max Participation</th>
																	<th className='text-center'>Duration</th>
																	<th>Start Time</th>
																	<th className='text-center'>End Time</th>
																	<th className='text-center'>Status</th>
																	<th></th>
																</tr>
															</thead>

															<tbody>
																{console.log(this.state.userdata, "this.state.userdata")}
																{this.state.userdata !== undefined ? (
																	this.state.userdata.map((obj, index) => (
																		<tr key={index}>
																			<Link
																				// to={`/userdetail/${obj.id}`}
																				className='display-content'>
																				<td>{index + 1}</td>
																				<td className='tables-name'>{obj.contestName} </td>
																				<td className='tables-customer'>
																					{obj.assetType == 1 ? "Stocks" : "Cryptocurrency"}
																				</td>
																				<td className='tables-phone'>{obj?.entryFee || "-"}</td>
																				<td className='tables-phone'>{obj?.minimumEntry ? obj.minimumEntry : ""}</td>
																				<td className='text-center'>{obj?.total_no_wins ? obj?.total_no_wins : 0}</td>
																				<td>{obj?.duration}</td>
																				<td>{obj?.startTime}</td>
																				<td>{obj?.endTime}</td>
																				<td>{obj?.status == 0 ? "pending" : "completed"}</td>
																			</Link>
																			<td className='text-right'>
																				<div className='dropdown'>
																					<a
																						href='#/'
																						className='dropdown-ellipses dropdown-toggle'
																						role='button'
																						data-toggle='dropdown'
																						aria-haspopup='true'
																						aria-expanded='false'>
																						<i className='fe fe-more-vertical' style={{ color: "#12263F" }} />
																					</a>
																					<div className='dropdown-menu dropdown-menu-right'>
																						<Link to={`/userdetail/${obj.id}`} className='dropdown-item'>
																							View Details
																						</Link>
																					</div>
																				</div>
																			</td>
																		</tr>
																	))
																) : (
																	<tr>
																		<td>No data Found...</td>
																	</tr>
																)}
															</tbody>
														</table>
													) : this.state.userType == "user" ? (
														<>
															<table>
																<thead>
																	<tr>
																		<th>#</th>

																		<th>
																			<a
																				href='#/'
																				className='textPink list-sort sort'
																				data-sort='tables-name'
																				onClick={() => this.sortBy("firstName")}>
																				User name
																			</a>
																		</th>

																		<th>
																			<a
																				href='#/'
																				className='textPink list-sort sort'
																				data-sort='tables-phone'
																				onClick={() => this.sortBy("phoneNumber")}>
																				phone
																			</a>
																		</th>
																		<th>
																			<a
																				href='#/'
																				className='textPink list-sort sort'
																				data-sort='tables-date'
																				onClick={() => this.sortBy("createdAt")}>
																				Date and time
																			</a>
																		</th>

																		<th>
																			<a
																				href='#/'
																				className='textPink list-sort'
																				data-sort='tables-date'
																				onClick={() => this.sortBy("isActive")}>
																				User Status
																			</a>
																		</th>
																		<th>
																			<a
																				href='#/'
																				className='textPink list-sort'
																				data-sort='tables-date'
																				onClick={() => this.sortBy("isActive")}>
																				Subscription
																			</a>
																		</th>
																		<th>
																			<a
																				href='#/'
																				className='textPink list-sort'
																				data-sort='tables-date'
																				onClick={() => this.sortBy("isActive")}>
																				Expiry
																			</a>
																		</th>

																		<th></th>
																	</tr>
																</thead>
																{LoadingIs ? (
																	<tbody>
																		<tr>
																			<td colSpan='9'>
																				<div className='spinner-border' role='status'>
																					<span className='sr-only'>Loading...</span>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																) : (
																	<tbody>
																		{this.state.userdata.length > 0 ? (
																			this.state.userdata.map((obj, index) => (
																				<tr key={index}>
																					<Link
																						// to={`/userdetail/${obj.id}`}
																						className='display-content'>
																						<td>{index + 1}</td>
																						<td className='tables-name'>{obj.customerId}</td>
																						<td className='tables-customer'>{`${obj.firstName} ${obj.lastName}`}</td>
																						<td className='tables-phone'>{obj?.phoneNumber || "-"}</td>
																						<td className='tables-phone'>{obj?.createdAt ? obj.createdAt : ""}</td>
																						<td>{this.getUserStatus(obj?.status)}</td>
																						<td className='tables-phone'>
																							{obj?.subscription ? obj.subscription : ""}
																						</td>
																						<td className='tables-phone'>{obj?.expiryDate ? obj.expiryDate : ""}</td>
																					</Link>
																					<td className='text-right'>
																						<div className='dropdown'>
																							<a
																								href='#/'
																								className='dropdown-ellipses dropdown-toggle'
																								role='button'
																								data-toggle='dropdown'
																								aria-haspopup='true'
																								aria-expanded='false'>
																								<i
																									className='fe fe-more-vertical'
																									style={{
																										color: "#12263F",
																									}}
																								/>
																							</a>
																							<div className='dropdown-menu dropdown-menu-right'>
																								<Link to={`/userdetail/${obj.id}`} className='dropdown-item'>
																									View Details
																								</Link>
																							</div>
																						</div>
																					</td>
																				</tr>
																			))
																		) : (
																			<tr>
																				<td>No data Found...</td>
																			</tr>
																		)}
																	</tbody>
																)}
															</table>
														</>
													) : (
														<table>
															{/* <thead> */}

															{/* <tr>
                          <th>#</th>
                          <th>
                            
                              Contest name
                        
                          </th>
                          <th >
                            
                              Contest Type
                          
                          </th>
                          <th className="text-center">
                            
                              Entry fees
                           
                          </th>
                          <th >
                            {" "}
                            
                              Min Participation
                            
                          </th>
                          <th className="text-center">
                            
                              Max Participation
                           
                          </th>
                          <th className="text-center">
                            
                              Duration
                         
                          </th>
                          <th >
                            
                              Start Time
                        
                          </th>
                          <th className="text-center">
                            
                              End Time
                            
                          </th>
                          <th className="text-center">
                            
                              Status
                        
                          </th>
                          <th></th>
                        </tr> */}
															{/* </thead> */}

															<tbody>
																<tr>
																	<td>No data Found...</td>
																</tr>
															</tbody>
														</table>
													)}
													{}

													{/* {modulesWithdraw.length > 0 ? (
                              modulesWithdraw.map((obj, index) => (
                              <tr key={obj.id}>
                              <td>{obj.name}</td>
                              <td style={{ textAlign: "end" }}>
                              <a
                              href="#ShowReportHistoryModal"
                              className="btn btn-primary btn-sm mr-3"
                              data-toggle="modal"
                              data-target="#ShowReportHistoryModal"
                              onClick={this.ViewHistoryGeneratedReports}
                              ref={this.modelOpenRef}
                              >
                              History
                              </a>
                              <button
                              className="btn btn-sm btn-outline-primary"
                              onClick={() =>
                              this.generateReportsWithdrawFromServer()
                              }
                              >
                              Generate report
                              </button>
                              </td>
                              </tr>
                              ))
                              ) : (
                              <tr>
                              <td>No data Found...</td>
                              </tr>
                              )} */}
												</tbody>
											)}
										</table>
									</div>
								</div>
								{/* <div className="col-12">
                    {this.state.totalPages > 0 && (
                    <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.totalPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    />
                    )}
                    </div> */}
							</div>
						</div>
					</div>

					<div
						className='modal fade'
						id='generateReport'
						tabIndex={-1}
						aria-labelledby='exampleModalLabel'
						aria-hidden='true'>
						<div className='modal-dialog modal-dialog-centered'>
							<div className='modal-content'>
								<div className='modal-header d-flex align-items-center'>
									<h2 className='modal-title' id='exampleModalLabel'>
										Generate Report
									</h2>
									<button
										type='button'
										className='close'
										data-dismiss='modal'
										aria-label='Close'
										onClick={this.onClickChange}>
										<span aria-hidden='true'>×</span>
									</button>
								</div>
								<div className='modal-body'>
									<h1 style={{ marginBottom: 0 }}>Are You Sure You Want To Generate Report.</h1>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										flexWrap: "wrap",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<button
										style={{
											marginRight: ".5rem",
											marginBottom: "1.5rem",
											width: "5rem",
										}}
										data-dismiss='modal'
										type='button'
										className='btn btn-primary'
										onClick={() => this.generateAndSaveReportsFromServer(this.state.generateReportValue)}>
										Yes
									</button>
									<button
										style={{
											marginLeft: ".5rem",
											marginBottom: "1.5rem",
											width: "5rem",
										}}
										type='button'
										className='btn btn-primary'
										data-dismiss='modal'
										aria-label='Close'
										onClick={this.onClickChange}>
										No
									</button>
								</div>
							</div>
						</div>
					</div>

					<ToastContainer position='top-center' autoClose={2000} />
					<ShowReportHistoryModal
						ViewHistoryGeneratedReportsData={this.state.ViewHistoryGeneratedReportsData}
						loadingHistory={this.state.loadingHistory}
						handleState={this.handleState}
						userRole={this.props.userRole}
					/>
					{/* <ShowReportWithdraw
            ViewHistoryWithdrawReportsData={this.state.ViewHistoryWithdrawReportsData}
            loadingHistoryWithdraw={this.state.loadingHistoryWithdraw}
            handleState={this.handleState}
            />
        */}
				</div>
			</>
		);
	}
}

export default withRouter(Report);
