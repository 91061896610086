import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "../Pages/Header/Navbar";
import Select from "react-select";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import { createContest, updateContest, getContestById } from "../api/apiService";
import { AUTH_TOKEN } from "../store/sessionStogrageKey";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { filterduplicate } from "../api/apiService";
import "react-quill/dist/quill.snow.css";

const type = [
  { value: "1", label: "Stocks" },
  { value: "2", label: "Cryptocurrency" },
];

const Contests = [
  { value: "6", label: "6" }
];


const Fee = [
  { value: 1, label: "Free" },
  { value: 2, label: "Paid" }
];

class AddContests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contestName: "",
      assetType: type[0].value,
      contest_type: Contests[0].value,
      totalEntries: "",
      total_no_wins: "",
      prizeMoney: "0",
      minimumEntry: "",
      entryFee: "0",
      rules: [
        {
          title: "",
          description: "",
        },
      ],
      contest_fee_type: "1",
      title: "",
      description: "",
      errorIs: {
        contestName: "",
        totalEntries: "",
        tradingSymbol: "",
        marketCapType: "",
        total_no_wins: "",
        prizeMoney: "",
        minimum_entry: "",
        title: "",
        description: "",
        entryFee: "",
        duration: "",
      },
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      rank: [{ rank: "", price: "" }],
      to: "create",
      currentTime: "",
      duration: "",
      loadingForm: false,
      contestduplicate: [],
      rulesdata: [],
    };
  }

  componentDidMount() {
    this.handleduplicate();
  }

  handleduplicate = async () => {
    const responsedata = await filterduplicate(this.props.location.search.split("=")[1]);
    let rulesdetails = [];
    responsedata.data.rules.map((e) => rulesdetails.push([e.title, e.description]));
    if (responsedata.status === 1) {
      this.setState({
        contestduplicate: responsedata.data,
        rulesdata: rulesdetails,
        contestName: responsedata.data.contestName,
        assetType: responsedata.data.assetType,
        contest_fee_type: responsedata.data.contest_fee_type,
        startTime: responsedata.data.startTime,
        endTime: responsedata.data.endTime,
        entryFee: responsedata.data.entryFee,
        duration: responsedata.data.duration,
        minimumEntry: responsedata.data.minimumEntry,
        totalEntries: responsedata.data.totalEntries,
        total_no_wins: responsedata.data.total_no_wins,
        prizeMoney: responsedata.data.prizeMoney,
        rank: responsedata.data.contestRanks,
        rules: [{ ...this.state.rules[0], "title": responsedata.data.rules[0].title, "description": responsedata.data.rules[0].description }]
      });
    }
  };

  handleSelectChange = (value, fieldName) => {
    const { errorIs } = this.state;
    if (fieldName === "contest_type") {
      if (value === "9") {
        errorIs.tradingSymbol = "";
        this.setState({
          [fieldName]: value,
          segments: this.state.segmentsOptions[1].value,
          tradingSymbol: "",
          errorIs,
        });
      } else {
        errorIs.marketCapType = "";
        this.setState({ [fieldName]: value });
      }
    } else if (fieldName === "segments") {
      if (this.state.contest_type === 6 && value === "EQUITY") {
        errorIs.tradingSymbol = "";
        this.setState({ [fieldName]: value, tradingSymbol: "", errorIs });
      } else if (this.state.contest_type === 6 && value === "INDICES") {
        errorIs.marketCapType = "";
        this.setState({
          [fieldName]: value,
          marketCapType: "",
          errorIs,
        });
      } else {
        this.setState({ [fieldName]: value, tradingSymbol: "" });
      }
    } else if (fieldName === "country") {
      const val = value;
      const ty = this.state.countryList.find((el) => el.value === val);
      this.setState({ [fieldName]: ty });
    } else if (fieldName === "groupId") {
      const val = value;
      const ty = this.state.groupList.find((el) => el.value === val);
      this.setState({ [fieldName]: ty });
    } else if (fieldName === "assetType") {
      if (value === "1") {
        this.setState({ [fieldName]: value });
      } else {
        errorIs.tradingSymbol = "";
        errorIs.marketCapType = "";
        this.setState({
          [fieldName]: value,
          marketCapType: "",
          tradingSymbol: "",
          country: { value: 247, label: "All" },
          errorIs,
        });
      }
    } else {
      this.setState({ [fieldName]: value });
    }
    if (fieldName === "contest_fee_type" && value === 3) {
      this.setState({ entryFee: "0", prizeMoney: "0" });
    }
    if (fieldName === "tradingSymbol") {
      this.validation("tradingSymbol", value);
    }
    if (fieldName === "marketCapType") {
      this.validation("marketCapType", value);
    }
  };

  handleAppend = () => {
    const { rank } = this.state;
    if (this.state.total_no_wins > rank.length) {
      let { icon, ...lastItem } = rank.pop();
      var dataListIs = [...rank, lastItem, { rank: "", price: 0 }];

      this.setState({
        rank: dataListIs,
      });
    }
  };

  handleDelete = (index) => {
    const { rank } = this.state;

    const deletedArr = rank.filter((_, i) => i !== index);
    this.setState(
      {
        rank: [],
      },
      () => {
        this.setState({
          rank: [...deletedArr],
        });
      }
    );

  };

  handleTextInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    this.validation(name, value);
  };

  handleRankTextInputChanges = (event) => {
    event.preventDefault();
    const { rank } = this.state;
    const { name, value } = event.target;
    console.log("name", value);
    let [inputName, index] = name.split("-");
    index = parseInt(index);

    let newObject;
    if (inputName === "rank") {
      newObject = {
        ...rank[index],
        rank: parseInt(value),
      };
    } else {
      newObject = { ...rank[index], price: parseInt(value) };
    }
    let tempList = [
      ...rank.slice(0, parseInt(index)),
      newObject,
      ...rank.slice(parseInt(index) + 1, rank.length),
    ];
    this.setState({
      rank: tempList,
      rankIndex: index,

    });
  };

  handleRuleTextChange = ({ target }) => {
    console.log(target, "wertyui");
    const value = target.value;
    const name = target.name;
    let singleRule = this.state.rules[0];
    this.setState({
      rules: [{ ...singleRule, [name]: value }],
      [name]: value,
    });
    this.validation(name, value);
  };

  onRankToggleEnabled = ({ target }) => {
    if (!target.checked) {
      return;
    }
    let { distributedPrizeMoney, total_no_wins, rank } = this.state;
    let [index] = target.name.split("-");
    index = parseInt(index);

    if (!distributedPrizeMoney || !total_no_wins) {
      toast.error(
        `Please Fill above fields 'Total Number of Winners' and 'Distributed Prize Money'`
      );
      this.setState({ checked: false });
      return;
    } else if (!rank[index].rank_from || !rank[index].price) {
      toast.error(
        "Please fill current rank and prize first, To calculate below"
      );
      this.setState({ checked: false });
      return;
    }

    distributedPrizeMoney = parseInt(distributedPrizeMoney);
    total_no_wins = parseInt(total_no_wins);

    let totalRank = 0,
      totalPrice = 0;
    for (let items of rank) {
      let rankCount = 0;
      if (items.rank_from === items.rank_to) rankCount = 1;
      else rankCount = parseInt(items.rank_to) - parseInt(items.rank_from) + 1;

      totalPrice += parseInt(items.price) * rankCount;
      totalRank += rankCount;
    }

    if (totalRank + 1 > total_no_wins) {
      toast.error(`Rank can't be more than ${total_no_wins}`);
      this.setState({ checked: false });
      return;
    }
    let new_rankObject = {};
    new_rankObject.rank_from = totalRank + 1;
    new_rankObject.rank_to = total_no_wins;
    new_rankObject.price =
      (distributedPrizeMoney - totalPrice) / (total_no_wins - totalRank);
    new_rankObject.icon = true;

    let { icon, ...lastItem } = rank.pop();
    this.setState({
      rank: [...rank, lastItem, new_rankObject],
    });
  };

  winnersLeft(whatToReturn) {
    const { rank, total_no_wins, distributedPrizeMoney } = this.state;
    if (total_no_wins === "" || distributedPrizeMoney === "") {
      return "";
    }
    let totalRank = 0,
      totalPrice = 0;
    for (let items of rank) {
      if (items.price === "" || items.rank_to === "") break;

      let rankCount = 0;
      if (items.rank_from === items.rank_to) rankCount = 1;
      else rankCount = parseInt(items.rank_to) - parseInt(items.rank_from) + 1;

      totalPrice += parseInt(items.price) * rankCount;
      totalRank += rankCount;
    }
    if (whatToReturn === "rank") {
      if (total_no_wins - totalRank >= 0) {
        return `Winning rank left ${total_no_wins - totalRank}`;
      } else {
        return `Winning rank exceeded by ${totalRank - total_no_wins}`;
      }
    } else {
      if (distributedPrizeMoney - totalPrice >= 0) {
        return `Winning Price left ${distributedPrizeMoney - totalPrice}`;
      } else if (
        !(totalPrice - distributedPrizeMoney === 0) &&
        !(totalPrice - distributedPrizeMoney > 0)
      ) {
        return "Cash Price Must Be Greater Or Equal To Zero";
      } else {
        return `Winning Price exceeded by ${totalPrice - distributedPrizeMoney
          }`;
      }
    }
  }

  priceLeftHandle() {
    const { rank, distributedPrizeMoney } = this.state;
    let totalPrice = 0;
    for (let items of rank) {
      if (items.price === "" || items.rank === "") break;
      let rankCount = 0;
      totalPrice += parseInt(items.price) * rankCount;
    }
    if (distributedPrizeMoney - totalPrice === 0) {
      return false;
    } else {
      return true;
    }
  }

  sessionExpired() {
    toast.warn("Your session expired, You have been logged out!", {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    localStorage.removeItem(AUTH_TOKEN);
    /**
     * @var cameFrom to redirect here again after login.
     */
    this.props.history.replace({
      pathname: "/",
      search: this.props.location.search ?? "",
      state: { cameFrom: "/AddContests", state: this.state },
    });
  }

  createNewContest = async (contestId) => {
    let current_time = moment(new Date()).format("DD/MM/YYYY  HH:mm:ss");
    let start_time = moment(this.state.startTime).format(
      "DD/MM/YYYY  HH:mm:ss"
    );
    let end_time = moment(this.state.endTime).format("DD/MM/YYYY  HH:mm:ss");
    let startWithCurrentDiff = moment(start_time, "DD/MM/YYYY HH:mm:ss").diff(
      moment(current_time, "DD/MM/YYYY HH:mm:ss")
    );
    let startTimeWithEndDiff = moment(end_time, "DD/MM/YYYY HH:mm:ss").diff(
      moment(start_time, "DD/MM/YYYY HH:mm:ss")
    );

    if (startWithCurrentDiff < 0) {
      toast.error("Start Time Should Be Greater Than Current Time ");
    } else {
      if (startTimeWithEndDiff < 0) {
        toast.error(
          "Start Date And Time Should Be Less Than End Date And Time "
        );
      }

      let {
        to,
        id,
        ...stateData
      } = this.state;
      if (stateData.country) {
        stateData = {
          ...stateData,
          country: stateData.country.value,
        };
      }
      if (stateData.groupId) {
        stateData = {
          ...stateData,
          groupId: stateData.groupId.value,
        };
      }

      if (stateData.assetType.toString() === "2") {
        delete stateData["exchange"];
        delete stateData["tradingSymbol"];
        delete stateData["segments"];
      }
      if (to === "edit") {
        delete stateData["pdfUrl"];
      }
      if (to === "duplicate") {
        delete stateData["contest_status"];
        delete stateData["updatedAt"];
        delete stateData["total_no_paid_users"];
        delete stateData["total_no_free_users"];
        delete stateData["pdfUrl"];
      }
      if (stateData.contest_fee_type === "1") {
        stateData.entryFee = "0";
        stateData.prizeMoney = "0";
        stateData.total_no_wins = "0";
        stateData.distributedPrizeMoney = "0";
        stateData.rank = [{ rank_from: "", rank_to: "", price: 0, icon: true }];
      }
      if (
        stateData.assetType.toString() === "1" &&
        stateData.contest_type.toString() === "9"
      ) {
        delete stateData["tradingSymbol"];
      }

      if (true) {
        let response;
        if (contestId) {
          response = await updateContest(contestId, stateData);
        } else response = await createContest(stateData);
        console.log(response, "qwertyui");
        if (response.status === 2) {
          this.sessionExpired();
        } else if (response.status === 1) {
          toast.success(response.message, {
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            this.props.history.push("/CreateContest");
          }, 2000);
        } else {
          toast.error(response.msg);
        }
      }
    }
  };

  retrieveSingleContest = async (contestId, to) => {
    let stateObject = this.state;
    let response = await getContestById(contestId);
    if (response.status === 2) {
      this.sessionExpired();
    } else if (response.status === 1) {
      let rank = response.data.rank;
      let newObject = rank.pop();
      newObject = { ...newObject, icon: true };
      let distributedPrizeMoney = response.data?.distributedPrizeMoney;
      let country = this.state.countryList.find(
        (el) => el.value === response?.data?.countryId
      );
      let groupId = response?.data?.groupId
        ? this.state.groupList.find(
          (el) => el.value === response?.data?.groupId
        )
        : { value: "", label: "" };
      let title = response?.data?.rules[0].title;
      let description = response?.data?.rules[0].description;
      let marketCapType = response?.data?.marketCapType
        ? response.data.marketCapType
        : "";

      this.setState({
        ...Object.assign(stateObject, response.data),
        loadingForm: false,
        to,
        distributedPrizeMoney,
        rank: [...rank, newObject],
        country: country,
        groupId: groupId,
        title: title?.length ? title : "",
        description: description?.length ? description : "",
        marketCapType: marketCapType,
      });
    } else {
      toast(response.msg);
    }
  };
  moment;
  moment;
  moment;
  moment;
  updateSubmitButton() {
    const { to, id } = this.state;

    if (to === "edit") {
      return (
        <button
          onClick={() => {
            setTimeout(() => {
              this.createNewContest(id);
            }, 500);
          }}
          className="btn btn-primary mr-3"
        >
          Submit
        </button>
      );
    } else if (to === "duplicate") {
      return (
        <button
          onClick={() => {
            setTimeout(() => {
              this.createNewContest();
            }, 500);
          }}
          className="btn btn-primary mr-3"
        >
          Create New
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            setTimeout(() => {
              this.createNewContest();
            }, 500);
          }}
          className="btn btn-primary mr-3"
        >
          Create
        </button>
      );
    }
  }

  validation(name, value) {
    const { errorIs, totalEntries, segments, assetType } = this.state;
    switch (name) {
      case "contestName":
        errorIs.contestName = !value.length ? "Required Field" : "";
        break;
      case "totalEntries":
        errorIs.totalEntries = !value.length ? "Required Field" : "";
        break;
      case "tradingSymbol":
        errorIs.tradingSymbol =
          !value.length && segments !== "EQUITY" && assetType !== "2"
            ? "Required Field"
            : "";
        break;
      case "marketCapType":
        errorIs.marketCapType =
          !value.length && segments !== "INDICES" && assetType !== "2"
            ? "Required Field"
            : "";
        break;
      case "total_no_wins":
        errorIs.total_no_wins = !value.length ? "Required Field" : "";
        break;
      case "prizeMoney":
        errorIs.prizeMoney = !value.length ? "Required Field" : "";
        break;
      case "minimum_entry":
        errorIs.minimum_entry = !value.length
          ? "Required Field"
          : parseInt(value) > parseInt(totalEntries)
            ? "Minimum Entries Should Be Less Than Or Equal To Total Number of Entries"
            : "";
        break;
      case "entryFee":
        errorIs.entryFee = !value.length
          ? "Required Field"
          : value === "0"
            ? "Entry Fees Should Be Greater Than Zero"
            : "";
        break;
      case "title":
        errorIs.title = !value.length ? "Required Field" : "";
        break;
      case "description":
        errorIs.description = !value.length ? "Required Field" : "";
        break;
      case "distributedPrizeMoney":
        errorIs.distributedPrizeMoney = !value.length ? "Required Field" : "";
        break;
      case "duration":
        errorIs.duration = !value.length ? "Required Field" : "";
        break;
      default:
        break;
    }
  }

  validateForm() {
    const {
      errorIs,
      contestName,
      totalEntries,
      tradingSymbol,
      total_no_wins,
      prizeMoney,
      minimum_entry,
      entryFee,
      title,
      description,
      distributedPrizeMoney,
      duration,
      segments,
      marketCapType,
      assetType,
    } = this.state;

    errorIs.contestName = !contestName.length ? "Required Field" : "";
    errorIs.totalEntries = !totalEntries.toString().length
      ? "Required Field"
      : "";
    errorIs.tradingSymbol =
      !tradingSymbol?.length && segments !== "EQUITY" && assetType !== "2"
        ? "Required Field"
        : "";
    errorIs.marketCapType =
      !marketCapType?.length && segments !== "INDICES" && assetType !== "2"
        ? "Required Field"
        : "";
    errorIs.minimum_entry = !minimum_entry.toString().length
      ? "Required Field"
      : parseInt(minimum_entry) > parseInt(totalEntries)
        ? "Minimum Entries Should Be Less Than Or Equal To Total Number of Entries"
        : "";
    errorIs.title = !title.length ? "Required Field" : "";
    errorIs.description = !description.length ? "Required Field" : "";
    errorIs.duration = !duration.length ? "Required Field" : "";

    if (this.state.contest_fee_type === "2") {
      errorIs.prizeMoney = !prizeMoney.toString().length
        ? "Required Field"
        : "";
      errorIs.entryFee = !entryFee.toString().length
        ? "Required Field"
        : entryFee?.toString() === "0"
          ? "Entry Fees Should Be Greater Than Zero"
          : "";
      errorIs.total_no_wins = !total_no_wins.toString().length
        ? "Required Field"
        : "";
      errorIs.distributedPrizeMoney = !distributedPrizeMoney.toString().length
        ? "Required Field"
        : "";
    }

    this.setState({ errorIs });

    let pass =
      !errorIs.marketCapType &&
      !errorIs.contestName &&
      !errorIs.totalEntries &&
      !errorIs.tradingSymbol &&
      !errorIs.minimum_entry &&
      !errorIs.title &&
      !errorIs.description &&
      !errorIs.duration;

    if (this.state.contest_fee_type === "2") {
      return (
        !errorIs.total_no_wins &&
        !errorIs.prizeMoney &&
        !errorIs.entryFee &&
        !errorIs.distributedPrizeMoney &&
        pass
      );
    } else if (this.state.contest_fee_type === "3") {
      return !errorIs.total_no_wins && !errorIs.distributedPrizeMoney && pass;
    } else {
      return pass;
    }
  }

  render() {
    const { rank, contest_fee_type, errorIs } = this.state;
    return (
      <>
        <Navbar />
        {true ? (
          <div className="main-content">
            <div className="header">
              <div className="container-fluid">
                <div className="header-body">
                  <div className="row align-items-end">
                    <div className="col">
                      <h6 className="header-pretitle"> Create </h6>
                      <h1 className="header-title"> Contests</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loadingForm ? (
              <div style={{ marginLeft: "2.5rem" }}>
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-5 mx-2">
                    <div className="form-group">
                      <label>Title of Contest</label>
                      <input
                        type="text"
                        className={`form-control ${errorIs.contestName ? `error` : ``
                          }`}
                        name="contestName"
                        value={this.state.contestName}
                        onChange={this.handleTextInputChange}
                      />
                      {errorIs.contestName ? (
                        <span className="err-msg">{errorIs.contestName}</span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-sm-5 mx-2">
                    <div className="form-group">
                      <label>Contest Type</label>
                      <Select
                        value={this.state.assetType === "1" ? type[0] : type[1]}
                        onChange={({ value }) => {
                          this.handleSelectChange(value, "assetType");
                        }}
                        isDisabled={
                          this.state.to === "duplicate" ||
                          this.state.to === "edit"
                        }
                        options={type}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="col-5 mx-2">
                    <div className="form-group">
                      <label>Contest fee</label>
                      <Select
                        value={{
                          label: Fee.find(
                            (el) => el.value === this.state.contest_fee_type
                          )?.label,
                        }}
                        onChange={({ value }) => {
                          this.handleSelectChange(value, "contest_fee_type");
                        }}
                        options={Fee}
                        placeholder=""
                      />
                      {errorIs.contestName ? (
                        <span className="err-msg">{errorIs.contestName}</span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-sm-5 mx-2">
                    <div className="form-group">
                      <label>Starts Date & Time</label>
                      <Flatpickr
                        value={this.state.startTime}
                        className="form-control"
                        onChange={(date) => {
                          this.setState({
                            startTime: new Date(date).toISOString(),
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-5 mx-2">
                    <div className="form-group">
                      <label>End Date & Time </label>
                      <Flatpickr
                        value={this.state.endTime}
                        className="form-control"
                        onChange={(date) => {
                          this.setState({
                            endTime: new Date(date).toISOString(),
                          });
                        }}
                      />
                    </div>
                  </div>

                  {contest_fee_type === 1 || contest_fee_type === 3 ? null : (
                    <React.Fragment>
                      <div className="col-sm-5 mx-2">
                        <div className="form-group">
                          <label>Entry Fees</label>
                          <div className="position-relative">
                            <input
                              type="number"
                              onKeyDown={(event) => {
                                if (
                                  event.keyCode === 38 ||
                                  event.keyCode === 40 ||
                                  event.keyCode === 69
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              onWheel={(event) => event.currentTarget.blur()}
                              className={`form-control padding-left ${errorIs.entryFee ? `error` : ``
                                }`}
                              name="entryFee"
                              value={this.state.entryFee}
                            />
                            <span class="rupees_icon icon-input">
                              <svg
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                width="401.998px"
                                height="401.998px"
                                viewBox="0 0 401.998 401.998"
                                style={{
                                  enableBackground: "new 0 0 401.998 401.998",
                                }}
                                xmlSpace="preserve"
                              >
                                <g>
                                  <path
                                    d="M326.62,91.076c-1.711-1.713-3.901-2.568-6.563-2.568h-48.82c-3.238-15.793-9.329-29.502-18.274-41.112h66.52
         c2.669,0,4.853-0.856,6.57-2.565c1.704-1.712,2.56-3.903,2.56-6.567V9.136c0-2.666-0.855-4.853-2.56-6.567
         C324.334,0.859,322.15,0,319.481,0H81.941c-2.666,0-4.853,0.859-6.567,2.568c-1.709,1.714-2.568,3.901-2.568,6.567v37.972
         c0,2.474,0.904,4.615,2.712,6.423s3.949,2.712,6.423,2.712h41.399c40.159,0,65.665,10.751,76.513,32.261H81.941
         c-2.666,0-4.856,0.855-6.567,2.568c-1.709,1.715-2.568,3.901-2.568,6.567v29.124c0,2.664,0.855,4.854,2.568,6.563
         c1.714,1.715,3.905,2.568,6.567,2.568h121.915c-4.188,15.612-13.944,27.506-29.268,35.691
         c-15.325,8.186-35.544,12.279-60.67,12.279H81.941c-2.474,0-4.615,0.905-6.423,2.712c-1.809,1.809-2.712,3.951-2.712,6.423v36.263
         c0,2.478,0.855,4.571,2.568,6.282c36.543,38.828,83.939,93.165,142.182,163.025c1.715,2.286,4.093,3.426,7.139,3.426h55.672
         c4.001,0,6.763-1.708,8.281-5.141c1.903-3.426,1.53-6.662-1.143-9.708c-55.572-68.143-99.258-119.153-131.045-153.032
         c32.358-3.806,58.625-14.277,78.802-31.404c20.174-17.129,32.449-39.403,36.83-66.811h47.965c2.662,0,4.853-0.854,6.563-2.568
         c1.715-1.709,2.573-3.899,2.573-6.563V97.646C329.193,94.977,328.335,92.79,326.62,91.076z"
                                  />
                                </g>{" "}
                                <g></g> <g> </g>
                                <g> </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g> <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                              </svg>
                            </span>
                          </div>
                          {errorIs.entryFee ? (
                            <span className="err-msg">{errorIs.entryFee}</span>
                          ) : null}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="col-5 mx-2">
                    <div className="form-group">
                      <label>Duration</label>
                      <input
                        type="text"
                        className={`form-control ${errorIs.duration ? `error` : ``
                          }`}
                        name="duration"
                        value={this.state.duration}
                        onChange={this.handleTextInputChange}
                      />
                      {errorIs.duration ? (
                        <span className="err-msg">{errorIs.duration}</span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-5 mx-2">
                    <div className="form-group">
                      <label>Min Number of Entries</label>
                      <input
                        type="text"
                        className={`form-control ${errorIs.minimumEntry ? `error` : ``
                          }`}
                        name="minimumEntry"
                        value={this.state.minimumEntry}
                        onChange={this.handleTextInputChange}
                      />
                      {errorIs.minimumEntry ? (
                        <span className="err-msg">{errorIs.minimumEntry}</span>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-5 mx-2">
                    <div className="form-group">
                      <label>Max Number of Entries</label>
                      <input
                        type="text"
                        className={`form-control ${errorIs.totalEntries ? `error` : ``
                          }`}
                        name="totalEntries"
                        value={this.state.totalEntries}
                        onChange={this.handleTextInputChange}
                      />
                      {errorIs.totalEntries ? (
                        <span className="err-msg">{errorIs.totalEntries}</span>
                      ) : null}
                    </div>
                  </div>

                  {contest_fee_type === "1" ? null : (
                    <div className="col-12">
                      <hr className="mb-5" />
                    </div>
                  )}
                </div>
                {this.state.contest_fee_type === "1" ? null : (
                  <React.Fragment>
                    <div className="row">
                      <div className="col">
                        <h2>Add Prize Details</h2>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-sm-5 mx-2">
                            <div className="form-group">
                              <label>
                                Total Winners to Declare in this Contest
                              </label>
                              <input
                                type="number"
                                onKeyDown={(event) => {
                                  if (
                                    event.keyCode === 38 ||
                                    event.keyCode === 40 ||
                                    event.keyCode === 69
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onWheel={(event) => event.currentTarget.blur()}
                                className={`form-control ${errorIs.total_no_wins ? `error` : ``
                                  }`}
                                name="total_no_wins"
                                value={this.state.total_no_wins}
                                onChange={this.handleTextInputChange}
                              />
                              {errorIs.total_no_wins ? (
                                <span className="err-msg">
                                  {errorIs.total_no_wins}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-5 mx-2">
                            <div className="form-group">
                              <label>
                                {contest_fee_type === "3"
                                  ? "Free Coins to be Distributed"
                                  : "Prize Money to be Distributed"}
                              </label>
                              <input
                                type="number"
                                onKeyDown={(event) => {
                                  if (
                                    event.keyCode === 38 ||
                                    event.keyCode === 40 ||
                                    event.keyCode === 69
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onWheel={(event) => event.currentTarget.blur()}
                                className={`form-control ${errorIs.prizeMoney ? `error` : ``
                                  }`}
                                name="prizeMoney"
                                value={this.state.prizeMoney}
                                onChange={this.handleTextInputChange}
                              />
                              {errorIs.prizeMoney ? (
                                <span className="err-msg">
                                  {errorIs.prizeMoney}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12">
                        {" "}
                        <hr className="mb-5 " />
                      </div>
                    </div>

                    {/*-------------------------------Rank Append div----------------------------*/}
                    {console.log("rank = ", rank)}
                    {this.state.rank &&
                      this.state.rank.map((obj, index) => (
                        <div className="row position-relative" key={index}>
                          <div className="col-sm-5 mx-2">
                            <div className="form-group">
                              <label>Rank</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Rank"
                                name={`rank-${index}`}
                                value={obj.rank}
                                onChange={this.handleRankTextInputChanges}
                              />
                            </div>
                          </div>
                          <div className="col-sm-5 mx-2">
                            <div className="form-group mr-3">
                              <div className="row">
                                <div className="col">
                                  {" "}
                                  <label>
                                    {contest_fee_type === "3"
                                      ? "Coins Prize"
                                      : "Cash Prize"}
                                  </label>
                                </div>
                                <div className="col-auto">
                                </div>
                              </div>

                              <input
                                type="number"
                                onKeyDown={(event) => {
                                  if (
                                    event.keyCode === 38 ||
                                    event.keyCode === 40 ||
                                    event.keyCode === 69
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onWheel={(event) => event.currentTarget.blur()}
                                className="form-control"
                                name={`price-${index}`}
                                value={obj.price}
                                onChange={this.handleRankTextInputChanges}
                              />
                            </div>
                            {(obj.price === 0 || obj.price > 0) && (
                              <i
                                className="fe fe-plus-circle  plus_icon"
                                onClick={this.handleAppend}
                              ></i>
                            )}
                          </div>
                        </div>
                      ))}
                    <div className="row position-relative">
                      <div className="col-sm-6 text-danger">
                        <p>{this.winnersLeft("rank")}</p>
                      </div>
                      <div className="col-sm-6 text-danger">
                        <p>{this.winnersLeft("price")}</p>
                      </div>
                    </div>

                    {/*-------------------------------Rank Append div----------------------------*/}
                  </React.Fragment>
                )}

                {console.log(this.state.rulesdata, "ooooooo")}
                <div className="row">
                  <div className="col-12">
                    <hr className="mb-5 " />
                  </div>

                  <div className="col-12">
                    <h2>Contest Descriptions & Rules</h2>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Contest Description</label>
                          <input
                            type="text"
                            placeholder="Description"
                            className={`form-control ${errorIs.title ? `error` : ``
                              }`}
                            name="title"
                            value={this.state.rules[0].title}
                            onChange={this.handleRuleTextChange}
                          />
                          {errorIs.title ? (
                            <span className="err-msg">
                              {errorIs.title}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Contest Rules</label>
                          <textarea
                            type="text"
                            placeholder="Rules"
                            className={`form-control ${errorIs.description ? `error` : ``
                              }`}
                            name="description"
                            value={this.state.rules[0].description}
                            onChange={this.handleRuleTextChange}
                          />
                          {errorIs.description ? (
                            <span className="err-msg">
                              {errorIs.description}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-center pb-5 my-3">
                    {this.updateSubmitButton()}
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setTimeout(() => {
                          this.props.history.goBack();
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            <ToastContainer position="top-center" autoClose={3000} />
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default withRouter(AddContests);
