import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./Header/Navbar";
import { EditBanner, viewdata } from "../api/apiService";

const Editbanner = (props) => {
  var [edit, setedit] = useState([]);
  const [photo, setphoto] = useState("");
  const [bannerimage, setbannerimage] = useState("");
  const [url, seturl] = useState("");
  const [bannerName, setbannerName] = useState("");
  const[Active,setActive]=useState("");


  const history = useHistory();

  const handleTextInputtittle = (event) => {
    setbannerName(event.target.value);
  };

  const urlHandle = (event) => {
    seturl(event.target.value);
  };

  const uploadbanner = (e) => {
    setphoto(URL.createObjectURL(e.target.files[0]));
    setbannerimage(e.target.files[0]);
  };

  useEffect(() => {
    Editbannerdata();
    setphoto("")
  }, []);

  const Editbannerdata = async () => {
    const datas = await viewdata(props.location.search.split("?")[1]);
    console.log(datas.data.name,"444")
    if (datas) {
      setedit(datas?.data?.image);
      seturl(datas?.data?.url)
      setbannerName(datas?.data?.name)
      setActive(datas?.data?.isActive)
    }
  };
  const last = async () => {
    const formData = new FormData();
    formData.append('file', bannerimage);
    formData.append("name", bannerName)
    formData.append("url", url)
    // formData.append("id", props.location.search.split("?")[1])
    const response = await EditBanner(formData,props.location.search.split("?")[1]);
    if (response.data.status === 1) {
      toast.success(response.data.message)
      setTimeout(() => {
        history.push("/bannerlist");
      }, 1000);
    }
  };

  return (
    <>
      <Navbar />
      <>
        <div className="main-content">
          <div className="header">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row align-items-end">
                  <div className="col">
                    <h1 className="header-title">Edit Banner</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-5 " style={{"padding": "5.5px 10.75px"}}>
                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="contestName"
                    value={bannerName}
                    onChange={handleTextInputtittle}
                  />
                </div>
                <div className="form-group">
                  <label>Upload photo</label>
                  <div class="">
                    <label for="formFile" class="for-label"></label>
                    <input
                      class="form-control "
                      type="file"
                      id="formFile"
                      onChange={(e) => uploadbanner(e)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>URL</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="www.https://example.com"
                    name="contestName"
                    value={url}
                    onChange={(e) => urlHandle(e)}
                  />
                </div>
                <div className="form-group" onClick={()=>setActive(!Active)}>{
                  Active?
                  <button className="btn badge-soft-success">Active</button>: <button className="btn badge-soft-danger">InActive</button>
                }
                </div>
              </div>
              <div className="col-5 mx-2 d-flex h-100 mt-5 pt-5">
                <div
                  className=""
                  style={{ height: "290px", width: "435px" }}
                >
                  <img
                    alt="..."
                    className="rounded w-100 mh-100 mw-100 "
                    src={
                      photo
                        ? photo
                        : 
                        // "https://imagesmetanew.s3.ap-south-1.amazonaws.com/" +
                        // "/" +
                        // edit?.bannerImages?.split("/")[1]
                        `https://kiiquimedias.s3.amazonaws.com/banner-images/${edit}`

                        
                    }
                  />
                </div>
              </div>
              {console.log(edit,"888")}
            </div>

            <div className="text-center">
              <div class="btn-group my-2" role="group">
                <button
                  type="button"
                  class="btn btn-danger rounded mx-2"
                  onClick={() => last()}
                >
                  Update
                </button>
                <button
                  type="button"
                  class="btn btn-warning rounded mx-2"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {/* <ToastContainer position="top-center" autoClose={2000} /> */}
        </div>
      </>
    </>
  );
};

export default Editbanner;