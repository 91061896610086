import React, { Component } from "react";
import moment from "moment";
class ShowReportHistoryModal extends Component {
  
  render() {
    const { ViewHistoryGeneratedReportsData, loadingHistory, handleState } =
      this.props;
    return (
      <React.Fragment>
        {console.log(ViewHistoryGeneratedReportsData,"555555")}
        <section>
          <div
            className="modal rightSidebar fade"
            id="ShowReportHistoryModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered"
              style={{ maxWidth: 950 }}
            >
              <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                  <h2 className="modal-title" id="exampleModalLabel">
                    Generated Report's Details
                  </h2>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      handleState({ ViewHistoryGeneratedReportsData: null })
                    }
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col mt-4">
                    <div className="card">
                      
                      <div className="table-responsive mb-0 table_height_custom scroll_bar pr_table">
                        <table className="table table-hover table-sm card-table table-center">
                          <thead>
                            <tr>
                              <th># </th>
                              <th>Date </th>
                              <th>Name </th>
                              <th>From Date</th>
                              <th>To Date</th>
                              <th>Report Status</th>
                              {/* <th>Report Email Date</th> */}
                              {/* <th>Report Email Status</th> */}
                              {/* <th></th> */}
                              <th>Action</th>
                            </tr>
                          </thead>
                          {loadingHistory ? (
                            <tbody>
                              <tr>
                                <td colSpan="2">
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {ViewHistoryGeneratedReportsData?.length > 0 ? (
                                ViewHistoryGeneratedReportsData.map(
                                  (obj, index) => (
                                    <tr key={obj.id}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {moment(
                                          obj.isReportGeneratedDate
                                        ).format("DD/MM/YYYY")}
                                      </td>
                                      <td>{obj.module}</td>
                                      <td>
                                        {moment(obj.fromDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {moment(obj.toDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {obj.isReportGenerated
                                          ? "Success"
                                          : "Pending"}
                                      </td>
                                      {/* <td>
                                        {moment(
                                          obj.isReportEmailsendDate
                                        ).format("DD/MM/YYYY")}
                                      </td> */}
                                      {/* <td> */}
                                        {/* {obj.isReportEmailsend
                                          ? "Success"
                                          : "Pending"}
                                      </td> */}
                                      {/* {this.props.userRole === 2 ? ( */}
                                        <td>
<a href={`http://43.204.94.42:3001/${obj.reportURL}`} className="btn btn-dark test-white " >Download</a>




                                          {/* {obj.fileReportUrl !== "" ? ( */}
                                            {/* <a
                                       
                                              href=""
                                              // onClick={() =>
                                              //   this.downloadFile(obj)
                                              // }
                                              
                                            >
                                          
                                              <span type="button" className="btn btn-dark text-white"
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#142a46",
                                                }}
                                              >
                                                Download
                                              </span>
                                            </a> */}
                                          {/* ) : (
                                            <span
                                              style={{
                                                cursor: "not-allowed",
                                                color: "#c1c1c1",
                                              }}
                                            >
                                              Download
                                            </span>
                                          )} */}
                                        </td>
                                      {/* ) : (
                                        ""
                                      )} */}
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td>No data Found...</td>
                                </tr>
                              )}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default ShowReportHistoryModal;
