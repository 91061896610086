import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import video from "../assets/videos/video.mp4";
import video2 from "../assets/videos/video2.mp4";
import video3 from "../assets/videos/video3.mp4";
import video4 from "../assets/videos/video4.mp4";

import imgg from "../assets/images/nature.jpg";
import imgg2 from "../assets/images/nature2.jpg";
import imgg3 from "../assets/images/nature3.jpg";
import imgg4 from "../assets/images/nature4.jpg";
import imgg5 from "../assets/images/nature5.jpg";
import imgg6 from "../assets/images/nature6.jpg";

const LightBox = () => {
  const [toggler, setToggler] = useState(false);
  return (
    <>
      <div style={{ width: "100%" }} className='glry'>
        <img
          src={imgg}
          onClick={() => setToggler(!toggler)}
          className='lightVdo'
        />

        {/* <button onClick={() => setToggler(!toggler)}>Toggle Lightbox</button> */}
        <FsLightbox
          toggler={toggler}
          sources={[video, video2, video3, video4, video2, video3]}
        />
        <div className='viewGlry' onClick={() => setToggler(!toggler)}>
          <p className='inline'> View Gallery</p>
          <span className='fe fe-chevron-right inline'></span>
        </div>
      </div>
    </>
  );
};

export default LightBox;
