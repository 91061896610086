import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "./Header/Navbar";
import { getPromotionDetail } from "../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AUTH_TOKEN } from "../store/sessionStogrageKey";
import { getReadableDateTime } from "../utils/utils";
import { CSVDownload, CSVLink } from "react-csv";

class ViewPromotions extends Component {
  state = {};

  componentDidMount() {
    if (this.props?.location.search) {
      let contestId = this.props?.location.search;
      const parameterMatched = contestId.match(/\?id=\d+/);

      if (!parameterMatched) {
        this.setState({ loadingStatus: "Incorrect URL parameter" });
      } else {
        contestId = parameterMatched.input.split("=")[1];
        this.retrievePromotion(contestId);
      }
    }
  }

  sessionExpired() {
    toast.warn("Your session expired, You have been logged out!", {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    localStorage.removeItem(AUTH_TOKEN);
    /**
     * @var cameFrom to redirect here again after login.
     */
    this.props.history.replace({
      pathname: "/",
      search: this.props?.location.search,
      state: { cameFrom: "/view-promotions" },
    });
  }

  retrievePromotion = async (promotionId) => {
    let response = await getPromotionDetail(promotionId);
    if (response.status == 2) {
      this.sessionExpired();
      return;
    } else if (response.status != 1) {
      toast.error(response.msg);
      return;
    }

    let data = response.data;
    this.setState({ promotionItem: Object.assign({}, data) });
  };

  allCouponCode() {
    const { promotionItem } = this.state;
    if (promotionItem) {
      let codes = promotionItem.promotionDetails.map((items) => [
        items.promotionsCode,
      ]);
      console.log("klklklkl", [["Promotion Code"], ...codes]);
      return [["Promotion Code"], ...codes];
    }
    return [["Promotion Code"]];
  }

  render() {
    const { promotionItem } = this.state;
    // if (!promotionItem)
    //   return (
    //     <div>
    //       <h1>Loading ...</h1>
    //     </div>
    //   );

    return (
      <>
        <Navbar />
        <div className='main-content'>
          <div className='header'>
            <div className='container-fluid'>
              <div className='header-body'>
                <div className='row align-items-end'>
                  <div className='col'>
                    <h6 className='header-pretitle'> View </h6>
                    <h1 className='header-title'> Promotions</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12 col-md-3 '>
                <div className='form-group'>
                  <label className='textPink'>Promotions Name</label>
                  <div className=''>om prakash</div>
                </div>
              </div>
              <div className='col-sm-12 col-md-3'>
                <div className='form-group'>
                  <label className='textPink'>No Of Coupons</label>
                  <div className=''>20</div>
                </div>
              </div>
              <div className='col-sm-12 col-md-3'>
                <div className='form-group'>
                  <label className='textPink'>Coupons Value</label>
                  <div className=''>80</div>
                </div>
              </div>
              <div className='col-sm-12 col-md-3'>
                <div className='form-group'>
                  <label className='textPink'>Start Date</label>
                  <div className=''>01/15/01</div>
                </div>
              </div>
              <div className='col-sm-12 col-md-3'>
                <div className='form-group'>
                  <label className='textPink'>End Date</label>
                  <div className=''>45/45/11</div>
                </div>
              </div>
              <div className='col-12'>
                <hr className='mb-5 w-100' />
              </div>
            </div>
            <div className='row'>
              {/* {this.props.userRole === 2 ? ( */}
              {/* <div className="col-sm-12">
                  <div className="col ml-auto text-right pb-3">
                    <CSVLink
                      data={this.allCouponCode()}
                      filename={`${promotionItem.promotionsName}.csv`}
                    >
                      <button class="btn btn-sm btn-primary">
                        Download CSV
                      </button>
                    </CSVLink>
                  </div>
                </div> */}
              {/* ) : (
                ""
              )} */}
              {/* <div className="col-sm-12 col-md-4">
                <div className="copun_card">
                  <ul className="m-0 p-0">
                    <li>coupon Code</li>
                    {promotionItem.promotionDetails.map((items) => (
                      <li>{items.useCode}</li>
                    ))}
                  </ul>
                </div>
              </div> */}
            </div>
            <div className='row p-3'>
              <div className='col-12 pl-0'>
                <label className='textPink'>Coupon Codes</label>
              </div>
              {/* {promotionItem.promotionDetails.map((items) => (
                <div className="col-3  copun_card p-2 pl-3">
                  {items.useCode}
                </div>
              ))} */}
            </div>
          </div>
          <ToastContainer position='top-center' autoClose={2000} />
        </div>
      </>
    );
  }
}

export default withRouter(ViewPromotions);
