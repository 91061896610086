import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class RightSidebar extends Component {
  state = {
    transaction: "",
    reference: "",
    option: "",
    show: false,
  };

  getKYCStatus = (status) => {
    // console.log(status, 'badge badge-soft-success')
    if (status === 0) {
      return <span className="badge badge-soft-primary">Pending</span>;
    } else if (status === 2) {
      return <span className="badge badge-soft-danger">Rejected</span>;
    } else if (status === 1) {
      return <span className="badge badge-soft-success">Approved</span>;
    }
  };

  handleTransRefNumber = (e) => {
    e.preventDefault();
    // console.log("+++ ", e.target.name, e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmitStatus = async () => {
    this.setState(
      { transaction: "", reference: "", option: "", show: false },
      () => {
        toast.success("oooooyyyyooooo ++++");
        this.props.renderFunction();
      }
    );

    
  };

  render() {
    const {
      // transaction, reference,
      option, show } = this.state;

    return (
      <React.Fragment>
        <section>
          <div
            className="modal rightSidebar fade"
            id="offcanvasDemo"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered"
              style={{ maxWidth: 700 }}
            >
              <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                  <h2 className="modal-title" id="exampleModalLabel">
                    Withdraw Requested Amount
                  </h2>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row mb-2">
                    <div className="col-4">
                      <label className="label_name">Requested Amount :</label>
                    </div>
                    <div className="col-8">Rs.{this.props.amount}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label className="label_name">Requested Status :</label>
                    </div>
                    <div className="col-8">
                      {this.getKYCStatus(this.props.status)}
                    </div>
                  </div>
                  {this.props.comments !== "" && (
                    <div className="row mb-2">
                      <div className="col-4">
                        <label className="label_name">Comment :</label>
                      </div>
                      <div className="col-8">{this.props.comments}</div>
                    </div>
                  )}
                  <div className="row mb-2 mt-3">
           
                  </div>
                </div>
                {show ? (
                  <div
                    className="table-responsive m-4 table_height scroll_bar mb-0 x_scroll_width contest_table"
                    style={{ width: "93%" }}
                  >
                    <div style={{ border: "1px solid #E3EBF6" }}>
                      <div className="modal-header d-flex align-items-center">
                        <h2 className="modal-title" id="exampleModalLabel">
                          {option}
                        </h2>
                        <button
                          type="button"
                          className="close"
                          
                          onClick={() =>
                            this.setState({
                              transaction: "",
                              reference: "",
                              option: "",
                              show: false,
                            })
                          }
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      
                      <div className="modal-body">
                        <h1 style={{ marginBottom: 0 }}>
                          Are You Sure You Want To {option}.
                        </h1>
                      </div>
                     
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          style={{
                            marginRight: ".5rem",
                            marginBottom: "1.5rem",
                            width: "5rem",
                          }}
                          
                          data-dismiss="modal"
                          type="button"
                          className="btn btn-primary"
                        
                        >
                          
                          Yes
                        </button>
                        <button
                          style={{
                            marginLeft: ".5rem",
                            marginBottom: "1.5rem",
                            width: "5rem",
                          }}
                          type="button"
                          className="btn btn-primary"
                         
                          onClick={() =>
                            this.setState({
                              transaction: "",
                              reference: "",
                              option: "",
                              show: false,
                            })
                          }
                        >
                          
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

        </section>
      </React.Fragment>
    );
  }
}
export default RightSidebar;
