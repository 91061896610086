import Navbar from "./Header/Navbar"
import ReactQuill from "react-quill" 
import "react-quill/dist/quill.snow.css"
import { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../Pages/Reactquill/react.css"
import QuillToolbar, { formats, modules } from "./Reactquill/QuillToolbar"
import { addPrivacyPolicy, getPrivacyPolicyApi } from "../api/apiService"

const Policy = () => {
  const [term, setterm] = useState(null)
 
  const x = document.getElementById("policyId")
 
  const handleChange = (e) => {
    setterm(e)   
    var strippedHtml = e.replace(/<[^>]+>/g, '');
  } 
  const handlesubmit = async () => {
    if (term !== null) {
      const obj = { privacyPolicy: term } 
      const dataPut = await addPrivacyPolicy(obj)
      if (dataPut.status === 1) {
        toast.success("Terms & Condition Added SuccessFully")
        getPrivacyPolicy();
      } else {
        toast.error("Something went wrong")
      }
    } else {
      toast.error("Please Provide An Input!")
    }
  }

  const getPrivacyPolicy = async () => {
    const getPolicy = await getPrivacyPolicyApi()
    setterm(getPolicy?.data?.privacyPolicy)
  }
  useEffect(()=>{
    getPrivacyPolicy()
  },[])
  return (
    <>
      <Navbar />
      <div className='main-content'>
        <div className='header'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-end'>
                <div className='col'>
                  <h1 className='header-title'>Privacy Policy</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container '>
          <QuillToolbar toolbarId={"t1"} />
          <ReactQuill  id={ "policyId"} value={term} placeholder='Write Your Policy Condition Here...' onChange={handleChange} modules={modules("t1")} formats={formats}  />
          <button className='btn btn-primary my-3 pinkBtn' onClick={handlesubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  )
}
export default Policy