import Navbar from "./Header/Navbar"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../Pages/Reactquill/react.css"
import QuillToolbar, { formats, modules } from "./Reactquill/QuillToolbar"
import { addPrivacyPolicy, getPrivacyPolicyApi } from "../api/apiService"
import { useEffect } from "react";

const AboutUs = () => {
  const [term, setterm] = useState("")

  const handleChange = (e) => {
    setterm(e)
  }
  const handlesubmit = async () => {
    if (term !== null) {
      const obj = { aboutUs: term }
      const dataPut = await addPrivacyPolicy(obj)
      if (dataPut.status === 1) {
        toast.success("Terms & Condition Added SuccessFully");
        getPrivacyPolicy();
      } else {
        toast.error("Something went wrong")
      }
    } else {
      toast.error("Please Provide An Input!")
    }
  }
  const getPrivacyPolicy = async () => {
    const getPolicy = await getPrivacyPolicyApi()
    console.log(getPolicy)
    setterm(getPolicy?.data?.aboutUs)
  }
useEffect(()=>{
  getPrivacyPolicy();
},[])
  return (
    <>
      <Navbar />
      <div className='main-content'>
        <div className='header'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-end'>
                <div className='col'>
                  <h1 className='header-title'>About Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container '>
          <QuillToolbar toolbarId={"t1"} />
          <ReactQuill value={term} placeholder='Write About us Here...' onChange={handleChange} modules={modules("t1")} formats={formats} defaultValue='' />
          <button className='btn btn-primary my-3 pinkBtn' onClick={handlesubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  )
}
export default AboutUs