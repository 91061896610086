import React, { Component, PureComponent } from "react";
import Navbar from "../Pages/Header/Navbar";
import { Link } from "react-router-dom";
import moment from "moment";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getDashboardData } from "../api/apiService";

import DashboardGraph from "./DashboardGraph";

export default class Dashboard extends Component {
  state = {
    startDate: "",
    endDate: "",
    today: "",
    data: [],
    selectStatus: { value: "", label: "select days ..." },
    selectValue: 1,
    dayDate: "",
  };

  componentDidMount() {
    const d = new Date();
    this.setState(
      {
        today: d,
      },
      () => {
        this.handleDashboardData("date");
      }
    );
  }

  handleDateValidation = (startDate) => {
    let a = moment(new Date(this.state.startDate)).format("DD-MM-YYYY");
    let b = moment(new Date(this.state.endDate)).format("DD-MM-YYYY");
    let start = a.split("-");
    let end = b.split("-");
    let startSum = start[0] + start[1] + start[2];
    let endSum = end[0] + end[1] + end[2];
    if (startSum >= endSum) {
      return true;
    } else {
      return false;
    }
  };

  handleDashboardData = async (type) => {
    const { startDate, endDate } = this.state;
    let fromDate;
    let toDate;

    if (startDate !== "" && endDate !== "") {
      fromDate = moment(new Date(startDate)).format("YYYY-MM-DD");
      toDate = moment(new Date(endDate)).format("YYYY-MM-DD");
      let response = await getDashboardData(fromDate, toDate);
      if (response.status === 1) {
        let res = response;
        this.setState({ data: res });
      } else {
        toast.error("Something Went Wrong");
      }
    } else {
      let response = await getDashboardData(startDate, endDate);
      if (response.status === 1) {
        let res = response;
        this.setState({ data: res });
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  render() {
    const { data } = this.state;

    return (
      <>
        <Navbar />
        <div className='main-content'>
          <div className='header'>
            <div className='container-fluid'>
              <div className='header-body'>
                <div className='row align-items-end'>
                  <div className='col ml-3'>
                    <h6 className='header-pretitle'>Overview</h6>

                    <h1 className='header-title'>Dashboard</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container-fluid '>
            <div className='row mb-4 ml-2'>
              <div
                className='col-12 d-flex'
                data-toggle='collapse'
                href='#multiCollapseExample1'
                role='button'
                aria-expanded='false'
                aria-controls='multiCollapseExample1'
              >
                <h4
                  style={{
                    color: "#95AAC9",
                    border: "1px solid #95AAC9",
                    borderRadius: ".375rem",
                    padding: "5px",
                  }}
                >
                  FILTER <i class='fe fe-chevron-down ml-2'></i>
                </h4>
              </div>
            </div>
            <div
              className='row collapse multi-collapse ml-2'
              id='multiCollapseExample1'
            >
              <div
                className='col-12 mb-3'
                style={{ borderBottom: "1px solid #E3EBF6" }}
              >
                <h6 style={{ color: "#95AAC9" }}>FILTER BY DATE</h6>
              </div>
              <div className='col-5 mx-2'>
                <div className='form-group'>
                  <label>From Date</label>
                  <Flatpickr
                    value={this.state.startDate}
                    className='form-control'
                    options={{
                      dateFormat: "d-m-Y",
                      maxDate: this.state.today,
                    }}
                    onChange={(date) => {
                      let valid = this.handleDateValidation(
                        new Date(date).toISOString()
                      );
                      if (valid) {
                        this.setState({
                          startDate: new Date(date).toISOString(),
                          endDate: new Date(date).toISOString(),
                        });
                      } else {
                        this.setState({
                          startDate: new Date(date).toISOString(),
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className='col-5 mx-3'>
                <div className='form-group'>
                  <label>To Date</label>
                  <Flatpickr
                    value={this.state.endDate}
                    className='form-control'
                    options={{
                      minDate: this.state.startDate,
                      maxDate: this.state.today,
                      dateFormat: "d-m-Y",
                    }}
                    onChange={(date) => {
                      this.setState({ endDate: new Date(date).toISOString() });
                    }}
                  />
                </div>
              </div>
              <div className='col-2'>
                <div className='form-group'>
                  <label style={{ marginBottom: "3.4rem" }}></label>
                  <button
                    onClick={() => {
                      this.handleDashboardData("date");
                    }}
                    className='btn btn-primary mr-3 pinkBtn'
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-5 col-md-5 col-xl-5 '>
                <div className='card'>
                  <div className='card-body p-5 grayText'>
                    <Link to={{ pathname: "/user" }}>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <h5 className='text-uppercase mb-2 pink'>
                            {" "}
                            Total Number of Users
                          </h5>
                          <span className='h2 mb-0 '>
                            {" "}
                            {data?.numberOfUsers || 0}
                          </span>
                        </div>

                        <div className='col-auto'>
                          <span className='h2 fe fe-user mb-0' />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='d-sm-none d-md-block col-md-1 col-lg-1 col-xl-1 '></div>

              <div className='col-12 col-lg-5 col-md-5 col-xl-6 '>
                <div className='card'>
                  <div className='card-body p-5'>
                    {/* <Link to={{ pathname: "/CreateContest" }}> */}
                    <Link >
                      <div className='row align-items-center'>
                        <div className='col'>
                          <h5 className='text-uppercase mb-2 pink'>
                            {" "}
                            Total number of subscribed users
                          </h5>
                          <span className='h2 mb-0'>
                            {" "}
                            {data?.pastContest || 0}{" "}
                          </span>
                        </div>
                        <div className='col-auto'>
                          <span className='h2 fe fe-user-check mb-0' />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <DashboardGraph />
        </div>
        <ToastContainer position='top-center' autoClose={2000} />
      </>
    );
  }
}
