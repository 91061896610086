import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Navbar from "../Pages/Header/Navbar";
import { toggleForBanner, viewdata } from "../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


class Bannerpageview extends Component {

  state = {
    alldata: [],
  };

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  ClickToggleLoan = async ({ target }) => {
    let type = this.state?.alldata[0]?.isActive === false ? 1 : 0
    let response = await toggleForBanner(type, this.state?.alldata[0]?.id);
    if (response.status === 1) {
      toast.success(response.message)
      setTimeout(() => {
        window.location.reload()
      }, 1500);
    } else {
      toast.error("Something Went Wrong");
    }
  };

  componentDidMount() {
    this.getviewbanner();
  }

  getviewbanner = async () => {
    let response = await viewdata(this.props.location.search.split("?")[1]);
    console.log(response,"DFSGDSFGDFSGDFSGDFSGDF")
    if (response.status === 1) {
      this.setState({
        alldata: response.data,
      });
    } else {
      toast.error("Something Went Wrong");
    }
  };

  render() {
    const { alldata } = this.state;
    const { history } = this.props;
    return (
      <>
        <Navbar />

        
          return (
            <>
              <div className="main-content">
                <div className="header">
                  <div className="container-fluid">
                    <div className="header-body">
                      <div className="row align-items-end">
                        <div className="col">
                          <h1 className="header-title">View Banner</h1>
                        </div>
                        {/* <button
                          type="button"
                          class={`btn btn-${e.isActive === true ? 'danger' : 'success'}`}
                          onClick={this.ClickToggleLoan}
                        >
                          {e.isActive === true ? 'Deactivate' : "Activate"}
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-5 mx-2">
                      <div className="form-group">
                        <label>Banner Tittle</label>
                        <input
                          type="text"
                          className="form-control"

                          name="bannerName"
                          value={alldata.name}

                        />
                      </div>
                      <div className="form-group">
                        <label>URL</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="www.https://example.com"
                          name="contestName"
                          value={alldata.url}
                        />
                      </div>

                      <div className="form-group my-4">
                        <button
                          type="button"
                          class={`btn btn-${alldata.isActive === true ? 'danger' : 'success'}`}
                          onClick={this.ClickToggleLoan}
                        >
                          {alldata.isActive === true ? 'Deactivate' : "Activate"}
                        </button>

                      </div>

                    </div>
                    <div className="col-5 mx-3 my-2">
                      <div
                        className=""
                        style={{ height: "280px", width: "435px" }}
                      >
                        <img
                          alt=""
                          className="rounded w-100 mh-100 mw-100"
                          src={
                            `https://kiiquimedias.s3.amazonaws.com/banner-images/${alldata.image}`
                          }
                        />
                        {/* {console.log(this.state.alldata[0]?.bannerImages[0]?.s3key,"555")} */}
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <div class="btn-group" role="group">
                      <button
                        type="button"
                        class="btn btn-warning rounded my-3"
                        onClick={() => history.goBack()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <ToastContainer position="top-center" autoClose={3000} />
              </div>
            </>
          );
      </>
    );
  }
}

export default withRouter(Bannerpageview);
