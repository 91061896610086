import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "Jan.",
    totalUser: 5,
    totalSubUser: 2,
  },
  {
    name: "Fab.",
    totalUser: 10,
    totalSubUser: 7,
  },
  {
    name: "March",
    totalUser: 20,
    totalSubUser: 10,
  },
  {
    name: "April",
    totalUser: 30,
    totalSubUser: 12,
  },
  {
    name: "May",
    totalUser: 45,
    totalSubUser: 30,
  },
  {
    name: "June",
    totalUser: 60,
    totalSubUser: 55,
  },
  {
    name: "July",
    totalUser: 65,
    totalSubUser: 60,
  },
  {
    name: "Aug.",
    totalUser: 75,
    totalSubUser: 65,
  },
  {
    name: "Sept.",
    totalUser: 80,
    totalSubUser: 70,
  },
  {
    name: "Oct.",
    totalUser: 85,
    totalSubUser: 83,
  },
  {
    name: "Nov.",
    totalUser: 95,
    totalSubUser: 89,
  },
  {
    name: "Dec.",
    totalUser: 100,
    totalSubUser: 98,
  },
];

export default function DashboardGraph() {
  return (
    <div className='container-fluid'>
      <hr className='my-5' />
      <div className='contaisner'>
        <p style={{ textAlign: "center" }}>User Graph</p>
        <div className='row'>
          <BarChart
            width={1024}
            height={400}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey='totalSubUser' fill='#64acff' />
            <Bar dataKey='totalUser' fill='#d62b70' />
          </BarChart>
        </div>
      </div>
    </div>
  );
}
