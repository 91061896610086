import Navbar from "./Header/Navbar";
import Select from "react-select";
import { useEffect, useState } from "react";
import {
  getBodyTypedata,
  getChildrendata,
  getEthnicitydata,
  getHobbiesdata,
  getHoroScopedata,
  getindustrydata,
  getLanguagedata,
  getLoveLanguagedata,
  getVocationalTrainingdata,
  getExercisedata,
  getPetsdata,
  getProfessiondata,
  getReligiondata,
  getlookingdata,
  getUniversitydata,
  getEducationLeveldata,
  getInterestdata,
  getMyersBriggsdata,
  getMaritalStatusdata,
  getBodyTypeview,
  getChildrenview,
  getEthnicityview,
  getHobbiesview,
  getHoroScopeview,
  getindustryview,
  getLanguageview,
  getExerciseview,
  getLookingview,
  getLoveLanguageview,
  getVocationalTrainingview,
  getPetsview,
  getProfessionview,
  getReligionview,
  getUniversityview,
  getEducationLevelview,
  getInterestview,
  getMyersBriggsview,
  getMaritalStatusview,
  getBodyTypedelete,
  getChildrendelete,
  getEthnicitydelete,
  getHobbiesdelete,
  getHoroScopedelete,
  getindustrydelete,
  getLanguagedelete,
  getLoveLanguagedelete,
  getPetsdelete,
  getProfessiondelete,
  getReligiondelete,
  getUniversitydelete,
  getEducationLeveldelete,
  getInterestdelete,
  getMyersBriggsdelete,
  getMaritalStatusdelete,
  getLookingdelete,
  getVocationalTrainingdelete,
  getExercisedelete,
  getGenderdata,
  getGenderdelete,
  getGenderview,
  getPronounsdata,
  getDietdata,
  getSmokingdata,
  getDrinkingdata,
  getDietview,
  getSmokingview,
  getPronounsview,
  getDrinkingview,
  getDietdelete,
  getDrinkingdelete,
  getSmokingdelete,
  getPronounsdelete,
  getPreferenceByData,
  getSexualityview,
  getSexualitydata,
  getSexualitydelete,
  updatePreference,
} from "../api/apiService";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "40%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflowY: "scroll",
    float: "left",
    height: "500px",
    position: "relative",

    
    // width: "100%",    
    // height: "100vh",
    // position: "relative",
  },
};

const Setting = () => {
  const [hideEdit, setHideEdit]  = useState(true)
  const [selectdata, setselectdata] = useState();
  const [updatedVal,setUpdatedVal]= useState();
  // const [box, setbox] = useState([1]);
  const [pop, setpop] = useState(false);
  const [dropdown, setdropdown] = useState([
    { id: 1, label: "BodyType", createdAt: "7-8-2022" },
    { id: 2, label: "Gender", createdAt: "7-8-2022" },
    { id: 2, label: "Sexuality", createdAt: "7-8-2022" },
    { id: 3, label: "Children", createdAt: "7-8-2022" },
    { id: 4, label: "Ethnicity", createdAt: "7-8-2022" },
    { id: 5, label: "Hobbies", createdAt: "7-8-2022" },
    { id: 6, label: "HoroScope", createdAt: "7-8-2022" },
    { id: 7, label: "industry", createdAt: "7-8-2022" },
    { id: 8, label: "Language", createdAt: "7-8-2022" },
    { id: 9, label: "LoveLanguage", createdAt: "7-8-2022" },
    { id: 10, label: "Pets", createdAt: "7-8-2022" },
    { id: 11, label: "Profession", createdAt: "7-8-2022" },
    { id: 12, label: "Religion", createdAt: "7-8-2022" },
    { id: 13, label: "University", createdAt: "7-8-2022" },
    { id: 14, label: "EducationLevel", createdAt: "7-8-2022" },
    { id: 15, label: "Interest", createdAt: "7-8-2022" },
    { id: 16, label: "MyersBriggs", createdAt: "7-8-2022" },
    { id: 17, label: "MaritalStatus", createdAt: "7-8-2022" },
    { id: 18, label: "Looking", createdAt: "7-8-2022" },
    { id: 19, label: "VocationalTraining", createdAt: "7-8-2022" },
    { id: 20, label: "Exercise", createdAt: "7-8-2022" },
    { id: 21, label: "DrinkingHabits", createdAt: "7-8-2022" },
    { id: 22, label: "SmokingHabits", createdAt: "7-8-2022" },
    { id: 23, label: "Diet", createdAt: "7-8-2022" },
    { id: 24, label: "Pronouns", createdAt: "7-8-2022" },

    // { id: 4, label: "Marital Status", createdAt: "7-8-2022" },
    // { id: 4, label: "Marital Status", createdAt: "7-8-2022" },
  ]);
  const [testDetails, setTestDetails] = useState([{ name: "" }]);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [type,setType] = useState("")
  const [editId,setEditId]= useState();
  const [modalvalue, setmodalvalue] = useState({
    AddData: false,
    View: false,
    Update: false,
    Delete: false,
    editData:false
  });
  const [remove, setremove] = useState([]);
  const [viewid, setviewid] = useState();
  const [viewdata, setviewdata] = useState([]);
  const [loader, setloader] = useState(false);
  const [breakmap, setbreakmap] = useState(true);
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
// console.log(updatedVal)
  
  const getviewcollectiondata = async (data) => {
    // console.log(data, "LLLLLLLLLLL");
    setloader(true);
    let responese;
    switch (data) {
      case "BodyType":
        responese = await getBodyTypeview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        break;
        case "Sexuality":
          responese = await getSexualityview();
          if (responese.status === 1) {
            // toast.success(responese.message);
            setviewdata(responese.data);
            setloader(false);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          break;
        case "Diet":
          responese = await getDietview();
          if (responese.status === 1) {
            // toast.success(responese.message);
            setviewdata(responese.data);
            setloader(false);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          break;
          case "SmokingHabits":
            responese = await getSmokingview();
            if (responese.status === 1) {
              // toast.success(responese.message);
              setviewdata(responese.data);
              setloader(false);
            } else if (responese.status === 0) {
              toast.error(responese.message);
            } else {
              toast.error("something went wrong!");
            }
            break;
            case "Pronouns":
              responese = await getPronounsview();
              if (responese.status === 1) {
                // toast.success(responese.message);
                setviewdata(responese.data);
                setloader(false);
              } else if (responese.status === 0) {
                toast.error(responese.message);
              } else {
                toast.error("something went wrong!");
              }
              break;
            case "DrinkingHabits":
                responese = await getDrinkingview();
                if (responese.status === 1) {
                  // toast.success(responese.message);
                  setviewdata(responese.data);
                  setloader(false);
                } else if (responese.status === 0) {
                  toast.error(responese.message);
                } else {
                  toast.error("something went wrong!");
                }
                break;
      case "Children":
        responese = await getChildrenview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
        case "Gender":
        responese = await getGenderview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        break;
      case "Ethnicity":
        responese = await getEthnicityview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "Hobbies":
        responese = await getHobbiesview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "HoroScope":
        responese = await getHoroScopeview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "industry":
        responese = await getindustryview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "Language":
        responese = await getLanguageview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);/
        break;
      case "LoveLanguage":
        responese = await getLoveLanguageview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "Pets":
        responese = await getPetsview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "Profession":
        responese = await getProfessionview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "Religion":
        responese = await getReligionview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "University":
        responese = await getUniversityview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "EducationLevel":
        responese = await getEducationLevelview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "Interest":
        responese = await getInterestview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "MyersBriggs":
        responese = await getMyersBriggsview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "MaritalStatus":
        responese = await getMaritalStatusview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "Looking":
        responese = await getLookingview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "VocationalTraining":
        responese = await getVocationalTrainingview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      case "Exercise":
        responese = await getExerciseview();
        if (responese.status === 1) {
          // toast.success(responese.message);
          setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        break;
      default:
        return "";
    }
  };



  const handleChange = (selectedOption) => {
    // setselectdata(selectedOption);
  };

  const HandleButton = () => {
    setpop(true);
    if (testDetails.length < 1) {
      // setbox([...box, 1]);
      setTestDetails([...testDetails, { name: "" }]);
    }
  };

  const HandleAdd = () => {
    setTestDetails([...testDetails, { name: "" }]);
  };

  const Handlecancel = (i) => {
    if (testDetails.length === 2) {
      setpop(false);
    }
    let newtestDetails = [...testDetails];
    newtestDetails.splice(i, 1);
    setTestDetails(newtestDetails);
  };

  let inputHandler = (i, e) => {
    let newtestDetails = [...testDetails];
    // console.log(newtestDetails);
    newtestDetails[i][e.target.name] = e.target.value;
    setTestDetails(newtestDetails);
  };

  const validatedata = (details) => {
    const data = details.find((e) => e == "");
    // console.log(details, "8888888888", data);
    if (data != undefined) {
      return false;
    } else {
      return true;
    }
  };

  const HandleSubmitdata = async () => {
    let postdata = [];
    let data = [selectdata.value, testDetails];
    testDetails.map((E) => {
      // console.log(E?.name.length, "dddddddd");
      // if (E.name.length > 0) {
      postdata.push(E?.name);
      // }
    });
    // console.log(postdata, "==========", validatedata(postdata));
    if (postdata.length > 0 && validatedata(postdata)) {
      // if (false) {
      var responese;
      switch (selectdata.value) {
        case "BodyType":
          responese = await getBodyTypedata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          getviewcollectiondata(viewid);

          break;
          case "Gender":
          responese = await getGenderdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          getviewcollectiondata(viewid);

          break;
          case "Sexuality":
            responese = await getSexualitydata(postdata);
            if (responese.status === 1) {
              toast.success(responese.message);
            } else if (responese.status === 0) {
              toast.error(responese.message);
            } else {
              toast.error("something went wrong!");
            }
            getviewcollectiondata(viewid);
  
            break;
          case "Pronouns":
          responese = await getPronounsdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          getviewcollectiondata(viewid);

          break;
          case "Diet":
          responese = await getDietdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          getviewcollectiondata(viewid);

          break;
          case "SmokingHabits":
          responese = await getSmokingdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          getviewcollectiondata(viewid);

          break;
          case "DrinkingHabits":
          responese = await getDrinkingdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          getviewcollectiondata(viewid);

          break;
        case "Children":
          responese = await getChildrendata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          getviewcollectiondata(viewid);

          break;
        case "Ethnicity":
          responese = await getEthnicitydata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "Hobbies":
          responese = await getHobbiesdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "HoroScope":
          responese = await getHoroScopedata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "industry":
          responese = await getindustrydata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "Language":
          responese = await getLanguagedata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "LoveLanguage":
          responese = await getLoveLanguagedata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "Pets":
          responese = await getPetsdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "Profession":
          responese = await getProfessiondata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "Religion":
          responese = await getReligiondata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "University":
          responese = await getUniversitydata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "EducationLevel":
          responese = await getEducationLeveldata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "Interest":
          responese = await getInterestdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "MyersBriggs":
          responese = await getMyersBriggsdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "MaritalStatus":
          responese = await getMaritalStatusdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "Looking":
          responese = await getlookingdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "VocationalTraining":
          responese = await getVocationalTrainingdata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        case "Exercise":
          responese = await getExercisedata(postdata);
          if (responese.status === 1) {
            toast.success(responese.message);
          } else if (responese.status === 0) {
            toast.error(responese.message);
          } else {
            toast.error("something went wrong!");
          }
          // console.log("jkfjgksdfg", responese);
          break;
        default:
          return "";
      }
    } else {
      toast.error("invalid details");
    }
    setmodalIsOpen(false);
    setmodalvalue({
      ...modalvalue,
      AddData: false,
      View: false,
      Update: false,
      Delete: false,
      // editData:false
    });
    let del = [...testDetails];
    setTestDetails(del.splice(0, 0));
  };

  const handleModal = (selectedOption, datatype) => {
    // console.log(selectedOption,"IDDDDDDDDDDDDDDDDDDDDDDDDDDDD")
    
    setType(selectedOption.label);
    setmodalIsOpen(true);
    switch (datatype) {
      case "AddData":
        setmodalvalue({ ...modalvalue, AddData: true });
        setselectdata({
          label: selectedOption?.label,
          value: selectedOption?.label,
        });
        break;
      case "View":
        setmodalvalue({ ...modalvalue, View: true });
        setviewid(selectedOption.label);
        break;
      case "Update":
        setmodalvalue({ ...modalvalue, Update: true });
        setselectdata({
          label: selectedOption?.label,
          value: selectedOption?.label,
        });
        break;

      case "Delete":
        setmodalvalue({ ...modalvalue, Delete: true });
        setviewid(selectedOption.label);
        break;

      default:
        break;
    }

    // console.log(selectdata, datatype);
  };

  const RemoveData = (e, data) => {
    // console.log(data?.id, "ppppppp");
    if (e.target.checked) {
      setremove([...remove, data.id]);
    } else {
      let removedhandle = remove.indexOf(data?.id);
      remove.splice(removedhandle, 1);
      setremove(remove);
    }
  };

  const HandleRemove = async (id) => {
    // console.log(id, "kkkkkk");
    let responese;
    let payload;
    switch (viewid) {
      case "BodyType":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getBodyTypedelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setobject(responese.status);
          // setviewid("");
          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        getviewcollectiondata(viewid);

        break;
      case "Children":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getChildrendelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          // setobject(responese.status);

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
        case "Sexuality":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getSexualitydelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          // setobject(responese.status);

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
        case "Diet":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getDietdelete(payload.id);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          // setobject(responese.status);

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
        case "DrinkingHabits":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getDrinkingdelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          // setobject(responese.status);

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
        case "SmokingHabits":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getSmokingdelete(payload.id);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          // setobject(responese.status);

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
        case "Pronouns":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getPronounsdelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          // setobject(responese.status);

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
        case "Gender":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getGenderdelete(payload.id);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          // setobject(responese.status);

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "Ethnicity":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getEthnicitydelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "Hobbies":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getHobbiesdelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "HoroScope":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getHoroScopedelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "industry":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getindustrydelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "Language":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getLanguagedelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewdata(responese.data);
          // setviewid("");

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "LoveLanguage":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getLoveLanguagedelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "Pets":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getPetsdelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewdata(responese.data);
          // setviewid("");

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "Profession":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getProfessiondelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewdata(responese.data);
          // setviewid("");

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "Religion":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getReligiondelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewdata(responese.data);
          // setviewid("");

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "University":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getUniversitydelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "EducationLevel":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getEducationLeveldelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "Interest":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getInterestdelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewdata(responese.data);
          // setviewid("");

          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "MyersBriggs":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getMyersBriggsdelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "MaritalStatus":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getMaritalStatusdelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewid("");

          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "Looking":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getLookingdelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "VocationalTraining":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getVocationalTrainingdelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      case "Exercise":
        payload = {
          id: id,
          isActive: false,
        };
        responese = await getExercisedelete(payload);
        if (responese.status === 1) {
          toast.success(responese.message);
          // setviewdata(responese.data);
          setloader(false);
        } else if (responese.status === 0) {
          toast.error(responese.message);
        } else {
          toast.error("something went wrong!");
        }
        // console.log("jkfjgksdfg", responese);
        getviewcollectiondata(viewid);

        break;
      default:
        return "";
    }
  };

const getPreference =async (type,id)=>{
  let d = type.split("")[0].toLowerCase()
  // console.log({id:id,type:d+type.slice(1)}, "DDDDDDDDDDDDDDDDDDDDDDDDDDDDD")
  const response = await getPreferenceByData({id:id,type:d+type.slice(1)});
  // console.log(response,"userrrrrrrrrrrrrrrrrrrrrrrrrr")
  setUpdatedVal(response?.data1?.name)
}

  const handleEditModal=(e)=>{
    setEditId(e.id);
    // console.log(e.id,"???????????????????????/")
    setmodalIsOpen(false);
    setmodalvalue({
      ...modalvalue,
      AddData: false,
      View: false,
      Update: false,
      Delete: false,
      editData:false
    });

    setmodalIsOpen(true)
    setmodalvalue({
      editData:true
    })
    getPreference(type , e.id)

  }

  const handlePreferenceUpdate= async (id, type)=>{
    // console.log({id,type,updatedVal})
    let d = type.split("")[0].toLowerCase()
    
    try{
      const response = await updatePreference({id,type:d+type.slice(1),name:updatedVal})
      toast.success(response.message)
      getviewcollectiondata(viewid);
      setmodalIsOpen(false);
      setmodalvalue({
        ...modalvalue,
        editData:false
      })
    }catch(error){
      toast.error(error)
    }
    
  }


  useEffect(() => {
    getviewcollectiondata(viewid);
  }, [viewid]);

 
  // console.log(editId,type , " ::::::::::::::::::::::::::::")
  return (
    <>
      <div className="section">
        {/* {console.log(remove, "ppppccc")} */}
        <Navbar />
        <div class="main-content">
          <div className="header">
            <div className="container-fluid">
              <div className="header-body">
                <div className="row align-items-end">
                  <div className="col">
                    <h6 className="header-pretitle">Overview</h6>
                    <h1 className="header-title">Settings</h1>
                  </div>
                  <div className="col-auto display-none"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="container ">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Label</th>
                  <th scope="col">view</th>
                  <th scope="col" className=" text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {dropdown.map((E, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{E?.label}</td>
                        <td>
                          <button
                            type="button"
                            class="btn pinkBtn btn-sm "
                            style={{color:"white"}}
                            onClick={() => handleModal(E, "View")}
                          >
                            view
                          </button>
                        </td>
                        <td className="text-center">
                          <div className="dropdown">
                            <a
                              className="dropdown-ellipses dropdown-toggle"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fe fe-more-vertical"
                                style={{ color: "#12263F" }}
                              />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <Link
                                onClick={() => handleModal(E, "AddData")}
                                className="dropdown-item"
                                data-target="#exampleModalLive"
                              >
                                AddData
                              </Link>
                              {/* <Link
                                onClick={() => handleModal(E, "View")}
                                className="dropdown-item "
                                data-toggle="modal"
                                data-target="#exampleModalLive"
                              >
                                View
                              </Link>
                              <Link
                                onClick={() => handleModal(E, "Update")}
                                className="dropdown-item "
                                data-toggle="modal"
                                data-target="#exampleModalLive"
                              >
                                Update
                              </Link> */}
                               {/* <Link
                                onClick={() => handleModal(E, "Update")}
                                className="dropdown-item "
                                data-toggle="modal"
                                data-target="#exampleModalLive"
                              >
                                Update
                              </Link> */}
                              <Link
                                onClick={() => handleModal(E, "Delete")}
                                className="dropdown-item "
                                data-toggle="modal"
                                data-target="#exampleModalLive"
                              >
                                Delete
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* modal */}
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setmodalIsOpen(false);
              setmodalvalue({
                ...modalvalue,
                AddData: false,
                View: false,
                Update: false,
                Delete: false,
                editData:false,
              });
              let del = [...testDetails];
              setTestDetails(del.splice(0, 0));
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {modalvalue?.AddData ? (
          <div className="container">
            <div className="row justify-content-between align-items-center col-md-12 ">
              <div className="col-md-5">
                <label>Level</label>
                <Select
                  value={selectdata}
                  onChange={handleChange}
                  // options={options}
                />
              </div>
              <div className="col-md-6 ">
                <button
                  type="button"
                  class="btn pinkBtn mt-4"
                  onClick={HandleButton}
                >
                  AddButton
                </button>
              </div>
            </div>
            <div className="row justify-content-between align-items-center col-md-12 my-4 ">
              {pop
                ? testDetails.map((e, index) => {
                    return (
                      <>
                        <div className="col-md-5">
                          <label htmlFor="exampleDataList" class="form-label">
                            Datalist example
                          </label>
                          <input
                            class="form-control"
                            name="name"
                            list="datalistOptions"
                            id="exampleDataList"
                            placeholder="Type to search..."
                            onChange={(e) => inputHandler(index, e)}
                          />
                          <span>
                            {e.name.length > 0 ? (
                              ""
                            ) : (
                              <span className="text-danger">
                                required field*
                              </span>
                            )}
                          </span>
                        </div>
                        <div className="col-md-6 d-flex ">
                          <button
                            type="button"
                            class="btn pinkBtn mt-4"
                            onClick={HandleAdd}
                          >
                            Add(+)
                          </button>
                          <button
                            type="button"
                            class="btn pinkBtn mt-4 mx-3"
                            onClick={() => Handlecancel(index)}
                          >
                            Cancel(-)
                          </button>
                        </div>
                      </>
                    );
                  })
                : ""}
            </div>
            <button
              type="button "
              class="btn pinkBtn mt-2 "
              onClick={HandleSubmitdata}
            >
              Submit
            </button>
            <button
              type="button "
              class="btn bg-info text-white mt-2 mx-3"
              onClick={() => {
                setmodalIsOpen(false);
                setmodalvalue({
                  ...modalvalue,
                  AddData: false,
                  View: false,
                  Update: false,
                  Delete: false,
                });
                let del = [...testDetails];
                setTestDetails(del.splice(0, 0));
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          ""
        )}
        {
          modalvalue?.editData ? 
          <div className="row justify-content-between align-items-center col-md-12 ">
          <div className="col-md-5">
            <label>Level</label>
            <input type="text" value={updatedVal} onChange={(e)=>setUpdatedVal(e.target.value)}/>
          </div>
          <div className="col-md-6 ">
            <button
              type="button"
              class="btn pinkBtn mt-4 btn-sm "
              onClick={()=>handlePreferenceUpdate(editId,type)}
            >
              Update
            </button>
          </div>
        </div>: ""
        }
        {modalvalue?.View ? (
          <div className="container">
            <div className="row justify-content-between align-items-center col-md-12 my-4 ">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Label</th>
                    <th scope="col">created At</th>
                    <th></th>
                    <th></th> 
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <div class="spinner-border text-danger" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : viewdata.length > 0 ? (
                    viewdata.map((E, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{E?.name}</td>
                            <td>{E?.createdAt.split("T")[0]}</td>
                            {/* <td>Delete</td> */}

                            <td>
                            <button
                className='btn btn-white btn-sm mb-0 multi_btn_select'
                href='#modalExportMasher'
                onClick={() => {
                  // allMasherDataShare("approved");
                  handleEditModal(E)
                }}
                data-bs-toggle='modal'>
              Edit
              </button>
                            </td>

                            
                            {/* <td><button onClick={()=>handleUpdateData(E)}>Edit</button></td> */}
                            {/* <td className="text-center">
                            <div className="dropdown">
                              <a
                                className="dropdown-ellipses dropdown-toggle"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  className="fe fe-more-vertical"
                                  style={{ color: "#12263F" }}
                                />
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <Link
                                  onClick={() => handleModal(E, "AddData")}
                                  className="dropdown-item"
                                  data-target="#exampleModalLive"
                                >
                                  AddData
                                </Link>
                                <Link
                                  onClick={() => handleModal(E, "View")}
                                  className="dropdown-item "
                                  data-toggle="modal"
                                  data-target="#exampleModalLive"
                                >
                                  View
                                </Link>
                                <Link
                                  onClick={() => handleModal(E, "Update")}
                                  className="dropdown-item "
                                  data-toggle="modal"
                                  data-target="#exampleModalLive"
                                >
                                  Update
                                </Link>
                                <Link
                                  onClick={() => handleModal(E, "Delete")}
                                  className="dropdown-item "
                                  data-toggle="modal"
                                  data-target="#exampleModalLive"
                                >
                                  Delete
                                </Link>
                              </div>
                            </div>
                          </td> */}
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <div class="my-3">
                      <span>no data found!!</span>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
            <div className='modal fade' id='modalExportMasher' tabIndex={-1} aria-labelledby='modalExportMasher' aria-hidden='true'>
              <div style={{height:200, width : 200}}>
                <p>hey</p>
              </div>

</div>
            {/* <button
              type="button "
              class="btn pinkBtn mt-2 "
              onClick={HandleSubmitdata}
            >
              Submit
            </button> */}
            <button
              type="button "
              class="btn bg-info text-white mt-2 mx-3"
              onClick={() => {
                setmodalIsOpen(false);
                setmodalvalue({
                  ...modalvalue,
                  AddData: false,
                  View: false,
                  Update: false,
                  Delete: false,
                });
                let del = [...testDetails];
                setTestDetails(del.splice(0, 0));
                // setviewid("");
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          ""
        )}
        {modalvalue?.Update ? (
          <div className="container">
            {/* <div className="row justify-content-between align-items-center col-md-12 ">
              <div className="col-md-5">
                <label>Level</label>
                <Select
                  value={selectdata}
                  onChange={handleChange}
                  // options={options}
                />
              </div>
              <div className="col-md-6 ">
                <button
                  type="button"
                  class="btn pinkBtn mt-4"
                  onClick={HandleButton}
                >
                  AddButton
                </button>
              </div>
            </div> */}
            <div className="row justify-content-between align-items-center col-md-12 my-4 ">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">S.no</th>
                    <th scope="col">Label</th>
                    <th scope="col">CreatedAt</th>
                    <th scope="col" className=" text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dropdown.map((E, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{E?.label}</td>
                          <td>{E?.createdAt}</td>
                          <td className="text-center">
                            <div className="dropdown">
                              <a
                                className="dropdown-ellipses dropdown-toggle"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  className="fe fe-more-vertical"
                                  style={{ color: "#12263F" }}
                                />
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <Link
                                  onClick={() => handleModal(E, "AddData")}
                                  className="dropdown-item"
                                  data-target="#exampleModalLive"
                                >
                                  AddData
                                </Link>
                                <Link
                                  onClick={() => handleModal(E, "View")}
                                  className="dropdown-item "
                                  data-toggle="modal"
                                  data-target="#exampleModalLive"
                                >
                                  View
                                </Link>
                                <Link
                                  onClick={() => handleModal(E, "Update")}
                                  className="dropdown-item "
                                  data-toggle="modal"
                                  data-target="#exampleModalLive"
                                >
                                  Update
                                </Link>
                                <Link
                                  onClick={() => handleModal(E, "Delete")}
                                  className="dropdown-item "
                                  data-toggle="modal"
                                  data-target="#exampleModalLive"
                                >
                                  Delete
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <button
              type="button "
              class="btn pinkBtn mt-2 "
              onClick={HandleSubmitdata}
            >
              Submit
            </button>
            <button
              type="button "
              class="btn bg-info text-white mt-2 mx-3"
              onClick={() => {
                setmodalIsOpen(false);
                setmodalvalue({
                  ...modalvalue,
                  AddData: false,
                  View: false,
                  Update: false,
                  Delete: false,
                });
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          ""
        )}
        {modalvalue?.Delete ? (
          <div className="container">
            <div className="row justify-content-between align-items-center col-md-12 my-4 ">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">S.No1</th>
                    <th scope="col">Label</th>
                    <th scope="col">CreatedAt</th>
                    <th scope="col" className=" text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <div class="spinner-border text-danger" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : viewdata.length > 0 ? (
                    viewdata.map((E, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{E?.name}</td>
                            <td>{E?.createdAt.split("T")[0]}</td>
                            <td className="text-right">
                              
                              <div className="multi_select_btn">
                                <input
                                  type="checkbox"
                                  className="list-checkbox custom-control-input inp_hide"
                                  id={"__" + index}
                                  // onChange={(e) => RemoveData(e, E)}
                                  onChange={() => HandleRemove(E?.id)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={"__" + index}
                                />
                                <label
                                  htmlFor={"__" + index}
                                  role="button"
                                  className=" btn btn-white btn-sm mb-0 multi_btn_select"
                                >
                                  <i className="fe fe-check-circle" /> Remove
                                </label>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <div className="my-2">
                      <span>no data found!!</span>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
            {/* <button
              type="button "
              class="btn pinkBtn mt-2 "
              onClick={HandleRemove}
            >
              Remove
            </button> */}
            <button
              type="button "
              class="btn bg-info text-white mt-2 mx-3"
              onClick={() => {
                setmodalIsOpen(false);
                setmodalvalue({
                  ...modalvalue,
                  AddData: false,
                  View: false,
                  Update: false,
                  Delete: false,
                });
                let del = [...testDetails];
                setTestDetails(del.splice(0, 0));
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};
export default Setting;
