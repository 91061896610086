import React, { Component } from "react";
import { NavLink } from "react-router-dom";
// import Toplogo from "../../assets/images/meta.png";
import { AUTH_TOKEN } from "../../store/sessionStogrageKey";
import { FaCcDinersClub } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  getSideNestedNav,
  setSideNestedNav,
  gettoken,
  setSideNestedNavShow,
} from "../../utils/utils";
import logo from "../../assets/images/whitelogo.png";

export default class Navbar extends Component {
  state = {
    toggle: getSideNestedNav(),
    toggledata: gettoken(),
  };

  toggledata = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
    setSideNestedNav(!this.state.toggle);
  };

  // toggledatashow = () => {
  //   this.setState({
  //     toggledata: !this.state.toggledata,
  //   });
  //   setSideNestedNavShow(!this.state.toggledata);
  // };

  render() {
    const date = new Date();
    return (
      <>
        <nav
          className="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark navbar-vibrant"
          id="sidebar"
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#sidebarCollapse"
              aria-controls="sidebarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <a className="logo navbar-brand" href="#/">
              <img
                style={{ filter: "brightness(1.2" }}
                className="navbar-brand-img mx-auto h-50 w-50 "
                src={logo}
                alt="..."
              />
            </a>

            <div className="collapse navbar-collapse" id="sidebarCollapse">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink to="/dashboard" className="nav-link">
                    <i className="fe fe-home" />
                    Dashboard
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/user" className="nav-link">
                    <i className="fe fe-user" />
                    Users
                  </NavLink>
                </li>
                <li className="nav-item">
                  {/* <NavLink to='/CreateContest' className='nav-link'>
                    {" "}
                    <span class='trophy_icon'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        class='bi bi-trophy'
                        viewBox='0 0 16 16'
                      >
                        <path d='M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z' />
                      </svg>
                    </span>{" "}
                    Contests
                  </NavLink> */}
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/promotions" className="nav-link">
                    <i className="fe fe-award" />
                    Promotions
                  </NavLink>
                </li> */}
                {/* <li className='nav-item'>
                  <NavLink to='/withdraw' className='nav-link'>
                    <i className='fe fe-dollar-sign' />
                    Withdraw
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink to="/bannerlist" className="nav-link">
                    <i className="fe fe-camera" />
                    Banner
                  </NavLink>
                </li>

                <li className="nav-item">
                  {/* <NavLink to="/promotions" className="nav-link">
                    <i className="fe fe-award" />
                    Coupon & Offer
                  </NavLink> */}
                </li>
                {/* <li className='nav-item'>
                  <NavLink to='/appconfiguration' className='nav-link'>
                    <FaCcDinersClub style={{ "margin-right": "10px" }} />
                    AppConfiguration
                  </NavLink>
                </li> */}

                {/* <li className="nav-item">
                                    <NavLink to="/send-notification" className="nav-link"><i className="fe fe-bell" />Send Notification</NavLink>
                                </li> */}
                <li className="nav-item">
                  <NavLink to="/report" className="nav-link">
                    <i className="fe fe-briefcase" />
                    Report
                  </NavLink>
                </li>

                <li className="nav-item">
                  <h4
                    className="nav-link"
                    type="button"
                    onClick={() => this.toggledata()}
                  >
                    <i className="fe fe-edit" />
                    Page Setting
                  </h4>

                  {this.state.toggle ? (
                    <ul
                      style={{
                        listStyle: "none",
                        width: "90%",
                        float: "right",
                      }}
                    >
                      <li className="nav-item">
                        <Link to="/policy" className="nav-link">
                          <i className="fe fe-shield" />
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/about-us" className="nav-link">
                          <i className="fe fe-info" />
                          About Us
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </li>

                {/* <li className='nav-item'>
                  <NavLink to='/setting' className='nav-link'>
                  <i className='fe fe-settings' />
                  Settings
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <Link to="/settings" className="nav-link">
                    <i className="fe fe-shield" />
                    Add Data
                  </Link>
                </li>

                {/* <li className='nav-item'>
                  <NavLink
                    to='/settingPenal'
                    className='nav-link'
                  >
                    <i className='fe fe-unlock' />
                    Setting
                  </NavLink>
                </li> */}
                <hr class="navbar-divider my-3 w-100"></hr>
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className="nav-link"
                    onClick={() => {
                      localStorage.removeItem(AUTH_TOKEN);
                    }}
                  >
                    <i className="fe fe-unlock" />
                    Logout
                  </NavLink>
                </li>
                <hr class="navbar-divider my-3"></hr>
              </ul>

              <div className="mt-auto" />
            </div>
            <div class="navbar-user d-md-block d-none" id="sidebarUser">
              <a href="#/" class="d-block text-center navbar-brand pt-0">
                <img
                  style={{ filter: "brightness(1.2" }}
                  src={logo}
                  alt="..."
                />
              </a>
              <p class="d-block text-white text-center mb-0 opacity-8 font-sm">
                Copyright © {date.getFullYear()}
                <br /> KiiQui
                <br />
                All Rights Reserved.
              </p>
            </div>
          </div>
        </nav>
      </>
    );
  }
}
