import React, { Component } from "react";
import Navbar from "./Header/Navbar";
import { Link, withRouter } from "react-router-dom";
import {
  getWithdrawList,
  putApprovalRejectStatus,
  putGroupApprovalRejectStatus,
} from "../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
// import Approval from './Popup/Approval';
// import Reject from './Popup/Reject';
import RightSidebar from "./Popup/RightSidebar";
import moment from "moment";
import Select from "react-select";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";

const types = [
  { value: "", label: "All" },
  { value: "Approved", label: "Under Process" },
  { value: "Rejected", label: "Rejected" },
  { value: "Pending", label: "Pending" },
  { value: "Processed", label: "Processed" },
];

class Withdraw extends Component {
  state = {
    currentPage: 1,
    LoadingIs: false,
    withdrawList: [],
    totalPages: 0,
    name: "",
    userId: "",
    withdrawId: "",
    comment: "",
    status: "",
    errorIs: { comment: "" },
    check: false,
    modalAmount: "",
    modalStatus: "",
    modalComments: "",
    searchQuery: "",
    selectStatus: { value: "", label: "Payment Status Filter" },
    showModal: false,
    type: "",
    ARStatus: "",
    startDate: "",
    endDate: "",
    today: "",
    checkAll: [],
    count: 1,
    indexCount: 0,
    dynLimit: 50,
    allowAll: false,
    showAll: true,
  };

  handlePageClick = (data) => {
    let selectedData = data.selected + 1;
    this.setState({ indexCount: data.selected }, () => {
      this.handleWithdrawList(
        selectedData,
        this.state.searchQuery,
        this.state.type
      );
    });
  };

  handleWithdrawList = async (page, search, type) => {
    const { startDate, endDate, dynLimit, showAll } = this.state;
    this.setState({ LoadingIs: true });
    let fromDate = "";
    let toDate = "";
    if (!showAll) {
      fromDate = moment(new Date(startDate)).format("YYYY-MM-DD");
      toDate = moment(new Date(endDate)).format("YYYY-MM-DD");
    }
    // console.log('fromDate , toDate', fromDate, toDate)
    let response = await getWithdrawList(
      page,
      search,
      type,
      fromDate,
      toDate,
      dynLimit
    );
    if (response.status === 1) {
      let res = response.data;
      let arr = [];
      let find = res.find(
        (obj) => obj.payment_status === 0 || obj.payment_status === 1
      );
      let checkAll = find ? [find] : [];
      res.forEach((item, i) => arr.push({ ...item, allow: false }));
      // console.log('checkAll', checkAll, arr)
      this.setState({
        checkAll: checkAll,
        withdrawList: arr,
        totalPages: response.totalPages,
        LoadingIs: false,
      });
    } else {
      toast.error(response.msg);
    }
  };

  onClickShow = (show) => {
    this.setState(
      {
        showAll: show,
        dynLimit: 50,
        currentPage: 1,
        indexCount: 0,
        searchQuery: "",
        type: "",
        selectStatus: { value: "", label: "Payment Status Filter" },
      },
      () => {
        this.handleWithdrawList(
          this.state.currentPage,
          this.state.searchQuery,
          this.state.type
        );
      }
    );
  };

  handleDateValidation = (startDate) => {
    let a = moment(new Date(startDate)).format("DD-MM-YYYY");
    let b = moment(new Date(this.state.endDate)).format("DD-MM-YYYY");
    let start = a.split("-");
    let end = b.split("-");
    let startSum = start[0] + start[1] + start[2];
    let endSum = end[0] + end[1] + end[2];
    if (startSum >= endSum) {
      return true;
    } else {
      return false;
    }
  };

  handleApproveRejDiv = (type) => {
    return (
      <div className='col-sm-2'>
        <div className='dropdown' style={{ marginTop: "1.8rem" }}>
          <a
            href='#/'
            className='dropdown-ellipses dropdown-toggle'
            role='button'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            <button className='btn btn-primary' style={{ width: "100px" }}>
              Action
            </button>
          </a>
          <div className='dropdown-menu dropdown-menu-right p-3'>
            <button
              className='drop_list mb-2 dropdown-item table-sm '
              data-backdrop='static'
              data-toggle='modal'
              data-target='#withdrawGroup'
              onClick={() =>
                this.setState({
                  ARStatus: "Approved",
                  count: this.state.withdrawList.filter(
                    (obj) => obj.allow === true
                  ).length,
                  status: type === 0 ? 1 : 3,
                })
              }
            >
              <span className='fe fe-eye mr-1'></span>{" "}
              {type === 0 ? "Approve" : "Processed"}
            </button>
            <button
              className='drop_list dropdown-item table-sm'
              data-backdrop='static'
              data-toggle='modal'
              data-target='#withdrawGroup'
              onClick={() =>
                this.setState({
                  ARStatus: "Reject",
                  count: this.state.withdrawList.filter(
                    (obj) => obj.allow === true
                  ).length,
                  status: 2,
                })
              }
            >
              <span className='fe fe-x mr-1'></span> Reject
            </button>
          </div>
        </div>
      </div>
    );
  };

  searchInput = ({ target }) => {
    let searchQuery = target.value;
    this.setState({ searchQuery });
    if (searchQuery.length === 0) {
      this.handleWithdrawList(1, "", this.state.type);
      return;
    } else if (searchQuery.length < 3) return;

    this.handleWithdrawList(1, searchQuery, this.state.type);
  };

  handleChange = (selectedOption) => {
    // console.log(`Option selected:`, selectedOption, selectedOption.value);
    this.setState(
      {
        selectStatus: selectedOption,
        type: selectedOption.value,
        allowAll: false,
      },
      () => {
        this.handleWithdrawList(
          this.state.currentPage,
          this.state.searchQuery,
          this.state.type
        );
      }
    );
  };

  compareBy = (key) => {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  };

  sortBy = (key) => {
    let arrayCopy = [...this.state.withdrawList];
    arrayCopy.sort(this.compareBy(key));
    this.setState({ withdrawList: arrayCopy });
  };

  ApprovalRejectStatusApiCall = async (
    userId,
    withdrawId,
    comment,
    status,
    searchQuery
  ) => {
    // console.log('ApprovalRejectStatusApiCall',userId, withdrawId, comment, status, searchQuery)
    let response = await putApprovalRejectStatus(
      userId,
      withdrawId,
      comment,
      status
    );
    if (response.status === 1) {
      // console.log('handleApprovalRejectStatus', response)
      this.setState(
        { name: "", userId: "", withdrawId: "", comment: "", status: "" },
        () => {
          this.handleWithdrawList(
            this.state.currentPage,
            searchQuery,
            this.state.type
          );
        }
      );
    } else {
      toast.error(response.msg);
    }
  };

  handleApprovalRejectStatus = () => {
    const { check, userId, withdrawId, comment, status, searchQuery } =
      this.state;
    if (check) {
      // console.log('validation',this.validation())
      if (this.validation()) {
        this.ApprovalRejectStatusApiCall(
          userId,
          withdrawId,
          comment,
          status,
          searchQuery
        );
      }
    } else {
      this.ApprovalRejectStatusApiCall(
        userId,
        withdrawId,
        comment,
        status,
        searchQuery
      );
    }
  };

  ApprovalRejectGroupApiCall = async (comment, status, searchQuery) => {
    // console.log("ApprovalRejectGroupApiCall", comment, status, searchQuery);
    let data = [];
    this.state.withdrawList.forEach((item) => {
      if (item.allow === true) {
        data.push({ withdrawId: item?.id, userId: item?.userId });
      }
    });
    // console.log("data ++", data);
    let response = await putGroupApprovalRejectStatus(data, comment, status);
    if (response.status === 1) {
      // console.log('handleApprovalRejectGroup', response)
      this.setState(
        { name: "", userId: "", withdrawId: "", comment: "", status: "" },
        () => {
          this.handleWithdrawList(
            this.state.currentPage,
            searchQuery,
            this.state.type
          );
        }
      );
    } else {
      toast.error(response.msg);
    }
  };

  handleApprovalRejectGroup = () => {
    const { comment, status, searchQuery } = this.state;
    if (status === 2) {
      if (this.validation()) {
        this.ApprovalRejectGroupApiCall(comment, status, searchQuery);
      }
    } else {
      this.ApprovalRejectGroupApiCall(comment, status, searchQuery);
    }
  };

  getKYCStatus = (status) => {
    if (status === 0) {
      return <span className='badge badge-soft-primary'>Pending</span>;
    } else if (status === 2) {
      return <span className='badge badge-soft-danger'>Rejected</span>;
    } else if (status === 1) {
      return <span className='badge badge-soft-primary'>Under Process</span>;
    } else {
      return <span className='badge badge-soft-success'>Processed</span>;
    }
  };

  onClickChange = () => {
    const { errorIs } = this.state;
    errorIs.comment = "";
    this.setState({
      name: "",
      userId: "",
      withdrawId: "",
      comment: "",
      status: "",
      errorIs,
    });
  };

  validation = () => {
    let { errorIs, comment } = this.state;
    errorIs.comment = !comment.length ? "Required Field " : "";
    this.setState({
      errorIs,
    });
    return !errorIs.comment;
  };

  validateForm = (name, value) => {
    let { errorIs } = this.state;
    // console.log('validateForm =', name, value)
    switch (name) {
      case "comment":
        errorIs.comment = !value.length ? "Required Field " : "";
        break;
      default:
        break;
    }
    this.setState({ errorIs });
    return !errorIs.comment;
  };

  handleRejectTextReason = (e) => {
    e.preventDefault();
    // console.log('e.target.value', e.target.value)
    this.setState({ comment: e.target.value }, () => {
      this.validateForm("comment", this.state.comment);
    });
  };

  toggleChange = (id) => {
    let arr = this.state.withdrawList;
    arr.forEach((item, i) => {
      if (item.id === id) {
        item.allow = !item.allow;
        return;
      }
    });
    // console.log('toggleChange', arr)
    this.setState({ withdrawList: arr });
  };

  toggleChangeAll = (e) => {
    let arr = this.state.withdrawList;
    arr.forEach((item, i) => {
      if (this.state.selectStatus.value === "Pending") {
        if (item.payment_status === 0) {
          item.allow = e.target.checked;
        }
      } else if (this.state.selectStatus.value === "Approved") {
        if (item.payment_status === 1) {
          item.allow = e.target.checked;
        }
      }
    });
    // console.log('toggleChangeAll', arr)
    this.setState({ withdrawList: arr, allowAll: e.target.checked });
  };

  // componentDidMount() {
  //   const d = new Date();
  //   this.setState(
  //     {
  //       today: d,
  //       startDate: new Date(d.getFullYear(), d.getMonth(), 1),
  //       endDate: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
  //     },
  //     () => {
  //       this.handleWithdrawList(
  //         this.state.currentPage,
  //         this.state.searchQuery,
  //         this.state.type
  //       );
  //     }
  //   );
  // }

  render() {
    const { LoadingIs, withdrawList, errorIs, indexCount, dynLimit } =
      this.state;

    return (
      <React.Fragment>
        <Navbar />
        <div className='main-content'>
          <div className='header'>
            <div className='container-fluid'>
              <div className='header-body'>
                <div className='row align-items-end'>
                  <div className='col'>
                    <h1 className='header-title'> Withdraw</h1>
                  </div>
                </div>
              </div>
              <div className='row mt-3'>
                <div
                  className='col-12 d-flex'
                  data-toggle='collapse'
                  href='#multiCollapseExample1'
                  role='button'
                  aria-expanded='false'
                  aria-controls='multiCollapseExample1'
                >
                  <h4
                    style={{
                      color: "#95AAC9",
                      border: "1px solid #95AAC9",
                      borderRadius: ".375rem",
                      padding: "5px",
                    }}
                  >
                    FILTER <i class='fe fe-chevron-down ml-2'></i>
                  </h4>
                </div>
                <div
                  className='col-12 collapse multi-collapse'
                  id='multiCollapseExample1'
                >
                  <div
                    className='col-12 mb-3 pl-0'
                    style={{ borderBottom: "1px solid #E3EBF6" }}
                  >
                    <h6 style={{ color: "#95AAC9" }}>FILTER BY DATE</h6>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className='form-group'>
                        <label>From Date</label>
                        <Flatpickr
                          value={this.state.startDate}
                          className='form-control'
                          options={{
                            dateFormat: "d-m-Y",
                            maxDate: this.state.today,
                          }}
                          onChange={(date) => {
                            let valid = this.handleDateValidation(
                              new Date(date).toISOString()
                            );
                            // console.log("valid", date);
                            if (valid) {
                              this.setState(
                                {
                                  startDate: new Date(date).toISOString(),
                                  endDate: new Date(date).toISOString(),
                                },
                                () => {
                                  this.handleWithdrawList(
                                    this.state.currentPage,
                                    this.state.searchQuery,
                                    this.state.type
                                  );
                                }
                              );
                            } else {
                              this.setState(
                                {
                                  startDate: new Date(date).toISOString(),
                                },
                                () => {
                                  this.handleWithdrawList(
                                    this.state.currentPage,
                                    this.state.searchQuery,
                                    this.state.type
                                  );
                                }
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-sm-5'>
                      <div className='form-group'>
                        <label>To Date</label>
                        <Flatpickr
                          value={this.state.endDate}
                          className='form-control'
                          options={{
                            minDate: this.state.startDate,
                            maxDate: this.state.today,
                            dateFormat: "d-m-Y",
                          }}
                          onChange={(date) => {
                            this.setState(
                              { endDate: new Date(date).toISOString() },
                              () => {
                                this.handleWithdrawList(
                                  this.state.currentPage,
                                  this.state.searchQuery,
                                  this.state.type
                                );
                              }
                            );
                          }}
                        />
                      </div>
                    </div>

                    {withdrawList.find(
                      (obj) => obj.allow === true && obj.payment_status === 0
                    )
                      ? this.handleApproveRejDiv(0)
                      : withdrawList.find(
                          (obj) =>
                            obj.allow === true && obj.payment_status === 1
                        )
                      ? this.handleApproveRejDiv(1)
                      : ""}

                    <div className='col-12'>
                      <div className='row'>
                        <div
                          className='col-sm-2'
                          style={{
                            marginTop: ".8rem",
                            marginRight: 0,
                            paddingRight: 0,
                          }}
                        >
                          <button
                            className={`btn ${
                              this.state.showAll ? "hollowBtn" : "btn-primary"
                            }`}
                            onClick={() => this.onClickShow(false)}
                          >
                            Show Date Data
                          </button>
                        </div>
                        <div
                          className='col-sm-2'
                          style={{ marginTop: ".8rem", paddingLeft: 0 }}
                        >
                          <button
                            className={`btn ${
                              this.state.showAll ? "btn-primary" : "hollowBtn"
                            }`}
                            onClick={() => this.onClickShow(true)}
                          >
                            Show All Data
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-12' style={{ marginTop: "1.5rem" }}>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='row align-items-center'>
                        <div className='col'>
                          <h4 className='card-header-title'>List</h4>
                        </div>
                        <div className='col-sm-6'>
                          <form className='d-flex'>
                            {/* {withdrawList.find(obj => obj.allow === true) ?
                            <div style={{ width: '27%', marginRight: '1.5rem' }}>
                              <div className="dropdown" style={{}}>
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <button className="btn btn-primary">Action</button>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right p-3">
                                  <button className="drop_list mb-2 dropdown-item table-sm " data-backdrop="static" data-toggle="modal" data-target="#withdrawGroup" onClick={() => this.setState({ ARStatus: 'Approved', count: this.state.withdrawList.filter(obj => obj.allow === true).length, status: 1 })}>
                                    <span className="fe fe-eye mr-1"></span> Approval
                                  </button>
                                  <button className="drop_list dropdown-item table-sm" data-backdrop="static" data-toggle="modal" data-target="#withdrawGroup" onClick={() => this.setState({ ARStatus: 'Reject', count: this.state.withdrawList.filter(obj => obj.allow === true).length, status: 2 })}>
                                    <span className="fe fe-x mr-1"></span> Reject
                                  </button>
                                </div>
                              </div>
                            </div>
                            : ''
                          } */}
                            <div style={{ width: "100%", marginRight: "1rem" }}>
                              <Select
                                onChange={this.handleChange}
                                options={types}
                                value={this.state.selectStatus}
                              />
                            </div>
                            <div className='input-group input-group-flush input-group-merge'>
                              <input
                                type='search'
                                className='form-control form-control-prepended search'
                                onChange={this.searchInput}
                                value={this.state.searchQuery}
                                placeholder='Search'
                              />
                              <div className='input-group-prepend'>
                                <div className='input-group-text'>
                                  <span className='fe fe-search' />
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "50%",
                                marginRight: ".3rem",
                                marginLeft: ".5rem",
                              }}
                            >
                              <select
                                className='form-control slect_frm'
                                value={dynLimit}
                                onChange={(event) => {
                                  this.setState(
                                    { dynLimit: event.target.value },
                                    () => {
                                      this.handleWithdrawList(
                                        this.state.currentPage,
                                        this.state.searchQuery,
                                        this.state.type
                                      );
                                    }
                                  );
                                }}
                              >
                                <option value='50'>Show 50</option>
                                <option value='70'>Show 70</option>
                                <option value='100'>Show 100</option>
                              </select>
                            </div>
                          </form>
                        </div>
                        <div className='col-auto' />
                      </div>
                    </div>
                    <div className='table-responsive mb-0 table_height scroll_bar mb-0 x_scroll_width contest_table'>
                      <table className='table table-hover table-sm card-table table-center'>
                        <thead>
                          <tr>
                            <th
                              className='d-flex'
                              style={{ justifyContent: "end" }}
                            >
                              {this.state.checkAll?.length > 0 &&
                              (this.state.selectStatus.value === "Pending" ||
                                this.state.selectStatus.value === "Approved") &&
                              this.props.userRole === 2 ? (
                                <input
                                  type='checkbox'
                                  checked={this.state.allowAll}
                                  onChange={this.toggleChangeAll}
                                  style={{ marginRight: ".4rem" }}
                                />
                              ) : (
                                ""
                              )}{" "}
                              #
                            </th>
                            <th>
                              <a
                                href='#/'
                                className='textPink list-sort'
                                data-sort='tables-row'
                                onClick={() => this.sortBy("transactionId")}
                              >
                                Txn id
                              </a>
                            </th>
                            <th>
                              <a
                                href='#/'
                                className='textPink list-sort'
                                data-sort='tables-row'
                                onClick={() => this.sortBy("userName")}
                              >
                                Username
                              </a>
                            </th>
                            <th>
                              <a
                                href='#/'
                                className='textPink list-sort'
                                data-sort='tables-row'
                                onClick={() => this.sortBy("userId")}
                              >
                                User Id
                              </a>
                            </th>
                            <th>
                              <a
                                href='#/'
                                className='textPink list-sort'
                                data-sort='tables-row'
                                onClick={() => this.sortBy("amount")}
                              >
                                Requested Amount
                              </a>
                            </th>
                            <th>
                              <a
                                href='#/'
                                className='textPink list-sort'
                                data-sort='tables-row'
                                onClick={() => this.sortBy("last_balance")}
                              >
                                Last Balance
                              </a>
                            </th>
                            <th>
                              <a
                                href='#/'
                                className='textPink list-sort'
                                data-sort='tables-row'
                                onClick={() => this.sortBy("createdAt")}
                              >
                                Requested Date & Time
                              </a>
                            </th>
                            <th>
                              <a
                                href='#/'
                                className='textPink list-sort'
                                data-sort='tables-row'
                                onClick={() => this.sortBy("updatedAt")}
                              >
                                Updated Date & Time
                              </a>
                            </th>
                            <th>
                              <a
                                href='#/'
                                className='textPink list-sort'
                                data-sort='tables-row'
                                onClick={() => this.sortBy("payment_status")}
                              >
                                Payment Status
                              </a>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        {LoadingIs ? (
                          <tbody>
                            <tr>
                              <td colSpan='10'>
                                <div class='spinner-border' role='status'>
                                  <span class='sr-only'>Loading...</span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {withdrawList.length > 0 ? (
                              withdrawList.map((obj, index) => (
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "end",
                                      }}
                                    >
                                      {" "}
                                      {(obj?.payment_status === 0 ||
                                        obj?.payment_status === 1) &&
                                      (this.state.selectStatus.value ===
                                        "Pending" ||
                                        this.state.selectStatus.value ===
                                          "Approved") &&
                                      this.props.userRole === 2 ? (
                                        <input
                                          type='checkbox'
                                          checked={obj.allow}
                                          onChange={() =>
                                            this.toggleChange(obj?.id)
                                          }
                                          style={{ marginRight: ".4rem" }}
                                        />
                                      ) : (
                                        ""
                                      )}{" "}
                                      {indexCount * 50 + (index + 1)}
                                    </div>
                                  </td>
                                  <td>{obj?.transactionId}</td>
                                  <td>
                                    <Link
                                      to={
                                        obj?.userId
                                          ? {
                                              pathname: `/userdetail/${obj.userId}`,
                                            }
                                          : "#"
                                      }
                                      className='display-content'
                                      style={{ cursor: "pointer" }}
                                    >
                                      {obj.userName}
                                    </Link>
                                  </td>
                                  <a
                                    href='#offcanvasDemo'
                                    className='display-content'
                                    data-toggle='modal'
                                    data-target='#offcanvasDemo'
                                    onClick={() =>
                                      this.setState({
                                        modalAmount: obj?.amount,
                                        modalStatus: obj?.payment_status,
                                        modalComments: obj?.comments,
                                      })
                                    }
                                  >
                                    <td>{obj?.customer || "-"}</td>
                                    <td>
                                      {Number.parseFloat(obj?.amount).toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>
                                      {Number.parseFloat(
                                        obj?.last_balance
                                      ).toFixed(2)}
                                    </td>
                                    <td>
                                      {moment(obj?.createdAt).format(
                                        "MM/DD/YYYY  h:mm a"
                                      )}
                                    </td>
                                    <td>
                                      {moment(obj?.updatedAt).format(
                                        "MM/DD/YYYY  h:mm a"
                                      )}
                                    </td>
                                    <td>
                                      {this.getKYCStatus(obj?.payment_status)}
                                    </td>
                                  </a>
                                  <td>
                                    {(obj?.payment_status === 0 ||
                                      obj?.payment_status === 1) &&
                                    this.props.userRole === 2 ? (
                                      <div className='dropdown'>
                                        <a
                                          href='#/'
                                          className='dropdown-ellipses dropdown-toggle'
                                          role='button'
                                          data-toggle='dropdown'
                                          aria-haspopup='true'
                                          aria-expanded='false'
                                        >
                                          <i
                                            className='fe fe-more-vertical'
                                            style={{ color: "#12263F" }}
                                          />
                                        </a>
                                        <div className='dropdown-menu dropdown-menu-right p-3'>
                                          <button
                                            className='drop_list mb-2 dropdown-item table-sm '
                                            data-backdrop='static'
                                            data-toggle='modal'
                                            data-target='#approval'
                                            onClick={() =>
                                              this.setState({
                                                name: obj?.userName,
                                                userId: obj?.userId,
                                                withdrawId: obj?.id,
                                                comment: "",
                                                status:
                                                  obj?.payment_status === 0
                                                    ? 1
                                                    : 3,
                                                check: false,
                                              })
                                            }
                                          >
                                            <span className='fe fe-eye mr-1'></span>{" "}
                                            {obj?.payment_status === 0
                                              ? "Approve"
                                              : "Processed"}
                                          </button>
                                          <button
                                            className='drop_list dropdown-item table-sm'
                                            data-backdrop='static'
                                            data-toggle='modal'
                                            data-target='#reject'
                                            onClick={() =>
                                              this.setState({
                                                userId: obj?.userId,
                                                withdrawId: obj?.id,
                                                comment: "",
                                                status: 2,
                                                check: true,
                                              })
                                            }
                                          >
                                            <span className='fe fe-x mr-1'></span>{" "}
                                            Reject
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan='10'>No data Found...</td>
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  {this.state.totalPages > 0 && (
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              </div>

              {/* End Container */}
            </div>
          </div>
        </div>

        {/* ---------------------------------------group Approve or reject------------------------------ */}

        <div
          className='modal fade withdrawGroupCss'
          id='withdrawGroup'
          tabIndex={-1}
          width={"10rem"}
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              {this.state.ARStatus === "Approved" ? (
                <>
                  <div className='modal-header d-flex align-items-center'>
                    <h2 className='modal-title' id='exampleModalLabel'>
                      {this.state.status === 1 ? "Approve" : "Processed"}
                    </h2>
                    <button
                      type='button'
                      className='close'
                      data-dismiss='modal'
                      aria-label='Close'
                      onClick={this.onClickChange}
                    >
                      <span aria-hidden='true'>×</span>
                    </button>
                  </div>
                  <div className='modal-body'>
                    <h3 style={{ marginBottom: 0 }}>
                      Are you sure you want to set the withdrawal status of{" "}
                      {this.state.count}{" "}
                      {this.state.count === 1 ? `record` : `records`} to
                      {this.state.status === 1 ? " Approve" : " Processed"} ?
                    </h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        marginRight: ".5rem",
                        marginBottom: "1.5rem",
                        width: "5rem",
                      }}
                      data-dismiss='modal'
                      type='button'
                      className='btn btn-primary'
                      onClick={this.handleApprovalRejectGroup}
                    >
                      Yes
                    </button>
                    <button
                      style={{
                        marginLeft: ".5rem",
                        marginBottom: "1.5rem",
                        width: "5rem",
                      }}
                      type='button'
                      className='btn btn-primary'
                      data-dismiss='modal'
                      aria-label='Close'
                      onClick={this.onClickChange}
                    >
                      No
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className='modal-header d-flex align-items-center'>
                    <h2 className='modal-title' id='exampleModalLabel'>
                      Reject
                    </h2>
                    <button
                      type='button'
                      className='close'
                      data-dismiss='modal'
                      aria-label='Close'
                      onClick={this.onClickChange}
                    >
                      <span aria-hidden='true'>×</span>
                    </button>
                  </div>
                  <div className='modal-body'>
                    <h3 style={{ marginBottom: 0 }}>
                      Are you sure you want to set the withdrawal status of{" "}
                      {this.state.count}{" "}
                      {this.state.count === 1 ? `record` : `records`} to Reject
                      ?
                    </h3>
                    <hr></hr>
                    <div className='form-group'>
                      <label for='transaction_id'>Reason</label>
                      <textarea
                        style={{ borderColor: errorIs.comment ? "red" : "" }}
                        value={this.state.comment}
                        placeholder='Leave your comment'
                        onChange={(e) => this.handleRejectTextReason(e)}
                        className='form-control'
                        rows='5'
                      ></textarea>
                      {errorIs.comment && (
                        <p style={{ color: "red" }}>Required Field</p>
                      )}
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      data-dismiss={this.state.comment.length ? "modal" : ""}
                      onClick={this.handleApprovalRejectGroup}
                    >
                      Reject
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* ---------------------------------- approval modal ----------------------------------- */}

        <div
          className='modal fade'
          id='approval'
          tabIndex={-1}
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header d-flex align-items-center'>
                <h2 className='modal-title' id='exampleModalLabel'>
                  {this.state.status === 1 ? "Approval" : ""}
                </h2>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={this.onClickChange}
                >
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body'>
                {this.state.status === 1 ? (
                  <h3 style={{ marginBottom: 0 }}>
                    Are You Sure You Want To Approve {this.state.name} ?
                  </h3>
                ) : (
                  <h4 style={{ marginBottom: 0 }}>
                    Are You Sure You Want To Update The Payment Status Of{" "}
                    {this.state.name} To Processed ?
                  </h4>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    marginRight: ".5rem",
                    marginBottom: "1.5rem",
                    width: "5rem",
                  }}
                  data-dismiss='modal'
                  type='button'
                  className='btn btn-primary'
                  onClick={this.handleApprovalRejectStatus}
                >
                  Yes
                </button>
                <button
                  style={{
                    marginLeft: ".5rem",
                    marginBottom: "1.5rem",
                    width: "5rem",
                  }}
                  type='button'
                  className='btn btn-primary'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={this.onClickChange}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* ---------------------------------- reject modal ----------------------------------- */}

        <div
          className='modal fade'
          id='reject'
          tabIndex={-1}
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className='modal-header d-flex align-items-center'>
                <h2 className='modal-title' id='exampleModalLabel'>
                  Reject
                </h2>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={this.onClickChange}
                >
                  <span style={{ color: "#000" }} aria-hidden='true'>
                    ×
                  </span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='form-group'>
                  <label for='transaction_id'>Reason</label>
                  <textarea
                    style={{ borderColor: errorIs.comment ? "red" : "" }}
                    value={this.state.comment}
                    placeholder='Leave your comment'
                    onChange={(e) => this.handleRejectTextReason(e)}
                    className='form-control'
                    rows='5'
                  ></textarea>
                  {errorIs.comment && (
                    <p style={{ color: "red" }}>Required Field</p>
                  )}
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-dismiss={this.state.comment.length ? "modal" : ""}
                  onClick={this.handleApprovalRejectStatus}
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <Approval /> */}
        {/* <Reject /> */}
        <RightSidebar
          amount={this.state.modalAmount}
          status={this.state.modalStatus}
          comments={this.state.modalComments}
          renderFunction={() => this.handleWithdrawList(1, "", "")}
        />
        <ToastContainer position='top-center' autoClose={2000} />
      </React.Fragment>
    );
  }
}
export default withRouter(Withdraw);
