import React from "react"; // useState, useEffect
import "./assets/css/theme.css";
import "./assets/css/custom.css";
import "./assets/font/feather/feather.css";
import Users from "./Pages/Users";
import Setting from "./Pages/Setting";
import SignIn from "./Pages/SignIn";
import UserDetail from "./Pages/UserDetail";
// import CreateContest from "./Pages/CreateContest";
// import AddContests from "./Pages/AddContests";
// import Viewcontest from "./Pages/Viewcontest";
import Dashboard from "./Pages/Dashboard";
import Report from "./Pages/Report";
import Withdraw from "./Pages/Withdraw";
import Banner from "./Pages/Banner";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Bannerlist from "./Pages/Bannerlist";
import AppConfiguration from "./Pages/AppConfiguration";
import Bannerpageview from "./Pages/Bannerpageview";
import Editbanner from "./Pages/Editbanner";
import Duplicatedata from "./Pages/Duplicatedata";
// import Editcontest from "./Pages/Editcontest";
import Promotions from "./Pages/Promotions";
// import AddPromotions from "./Pages/AddPromotions";
import ViewPromotions from "./Pages/ViewPromotions";
import Policy from "./Pages/Policy";
import AboutUs from "./Pages/AboutUs";
import { ToastContainer } from "react-toastify";
import Settingpenal from "./Pages/SettingPenal";
import { useState } from "react";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("authToken") ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

function App() {
  const [policyId,setPolicyId]=useState();
  return (
    
    <Router>
      <div>
        <Switch>
          {/* modify line */}
          <Route exact path="/">
            <SignIn />{" "}
          </Route>
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/user" component={Users} />
          {/* <PrivateRoute exact path='/CreateContest' component={CreateContest} /> */}
          <PrivateRoute
            exact
            path="/userdetail/:userid"
            component={UserDetail}
          />
          {/* <PrivateRoute exact path='/Viewcontest' component={Viewcontest} /> */}
          <Route exact path="/withdraw" component={Withdraw} />
          <Route exact path="/report" component={Report} />
          <Route exact path="/setting" component={Setting} />
          {/* <Route exact path='/AddContests' component={AddContests} /> */}
          <Route exact path="/bannerlist" component={Bannerlist} />
          <Route exact path="/banner" component={Banner} />
          <Route exact path="/appconfiguration" component={AppConfiguration} />
          <Route exact path="/bannerpageview" component={Bannerpageview} />
          <Route exact path="/editbanner" component={Editbanner} />
          <Route exact path="/duplicatedata" component={Duplicatedata} />
          {/* <Route exact path='/editcontest' component={Editcontest} /> */}
          <Route exact path="/promotions" component={Promotions} />
          {/* <Route exact path='/add-promotions' component={AddPromotions} /> */}
          <Route exact path="/view-promotions" component={ViewPromotions} />
          <Route exact path="/policy" component={Policy} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/settings" component={Settingpenal} />

          {/* <Route exact path="/contestkyp" component={Contestkyp} /> */}
        </Switch>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </Router>
  );
}

export default App;
