
export function getReadableDateTime(timeStamp){
    let date = new Date(timeStamp)
    return date.toLocaleString()
}

export const getSideNestedNav = () => JSON.parse(localStorage.getItem('sidenavnested'))
export const gettoken = () => JSON.parse(localStorage.getItem('gettokendata'))


export const setSideNestedNav = (val) => localStorage.setItem('sidenavnested', val)

export const setSideNestedNavShow = (val) => localStorage.setItem('gettokendata', val)
